import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {PictureOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import ListUploadedMediaItem from '../../../modules/comman/ListComponenets/ListUploadedMediaItem'
import {config} from '../../../comman/constants'
import {invoiceOrderApi} from '../redux/OrderCRUD'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

interface IRenderFormikForm {
  values: IImportInventoryFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IImportInventoryFormValues {
  id: string
  file_name: string
}

interface IImportInventoryProps {
  data?: any
  handleCancel?: any
}

export class UploadVendorInvoiceForm extends React.Component<IImportInventoryProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      viewFileUploadModal: false,
      isLoading: false,
      uploadMedia: [],
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IImportInventoryFormValues) => {
    const errors: any = {}

    if (!values.file_name) {
      errors.file_name = 'Please select file'
    }

    return errors
  }

  public handleSubmit = async (values: IImportInventoryFormValues, action: any) => {
    try {
      this.setState({
        isLoading: true,
      })

      let invoiceSubmitData = await invoiceOrderApi(values)

      if (invoiceSubmitData && invoiceSubmitData.status == 200) {
        Toaster({
          type: 'success',
          title: 'Order',
          description: 'Invoice Updated Successfully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Failed To Update Invoice',
        })
      }
      this.props.handleCancel()
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        isLoading: true,
      })
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (file_name: string, FileData: Array<any>, customFileData: Object) => {
    if (file_name && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
    })
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    const {data} = this.props
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  id: data.order_id ? data.order_id : '',
                  file_name: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      {/* {data.invoice_number && data.invoice_url ? (
                        <span style={{fontSize: '16px', fontWeight: 700, marginBottom: '10px'}}>
                          Uploaded Invoice :
                          <a
                            style={{display: 'inline-block', paddingLeft: '10px'}}
                            target='blank'
                            href={data.invoice_url}
                          >
                            <div>{data.invoice_number}</div>
                          </a>
                        </span>
                      ) : (
                        <div style={{fontSize: '16px', fontWeight: 700, marginBottom: '10px'}}>
                          No Invoice Uploaded
                        </div>
                      )} */}
                      <div className='form-group row'>
                        <div
                          style={{
                            display: 'inline-block',
                            width: '100%',
                            marginTop: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <InputSingleFileUpload
                            buttonLabel={'Upload Invoice File'}
                            onBlur={handleBlur}
                            error={errors}
                            touched={touched}
                            name='file_name'
                            input={{
                              id: 'upload-invoice',
                              name: config.invoiceUploadKey,
                              action: config.vendorInvoiceUploadPath,
                              headers: {
                                authorization: 'authorized-text',
                              },
                              accept: config.invoiceFileExtensionsForDropZone,
                              acceptMimeTypes: config.invoiceFileExtensionsMimeTypes,
                              mediaType: config.invoiceUploadKey,
                              mediaModuleType: config.invoiceUploadKey,
                            }}
                            onUpload={(
                              file_name: string,
                              FileData: Array<any>,
                              customFileData: Object
                            ) => {
                              this.uploadMedia(file_name, FileData, customFileData)
                              setFieldValue(
                                'file_name',
                                file_name && FileData.length >= 1 ? file_name : ''
                              )
                            }}
                          />
                          {/* <div className='fv-plugins-message-container text-primary mt-2 d-flex justify-content-end'>
                              <div className='fv-help-block'>
                                <a href={config.downloadSampleFilePath} target='_blank'>{'Download Sample file'}</a>
                              </div>
                            </div> */}
                          {values.file_name &&
                          this.state.uploadMedia &&
                          this.state.uploadMedia[0] &&
                          this.state.uploadMedia.length >= 1 ? (
                            <ListUploadedMediaItem
                              name={this.state.uploadMedia[0].response.file}
                              url={`${config.importStockPreviewPath}${this.state.uploadMedia[0].response.file}`}
                            />
                          ) : null}
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Upload</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
