import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {changeOrderStausDataApi} from '../redux/OrderCRUD'

interface IRenderFormikForm {
  values: IFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IFormValues {
  status: string
  dollar_rate: any
}

interface IEditProps {
  data: any
  handleCancel?: any
  rowIds: any
}

export class ChangeOrderStatusForm extends React.Component<IEditProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async (values: IFormValues, action: any) => {
    const {data, rowIds, handleCancel} = this.props
    console.log('Basic Value - ', values)
    try {
      let orderData: any = {}
      orderData.ids = rowIds
      if (values.dollar_rate) {
        orderData.dollar_rate = values.dollar_rate
      }
      if (values.status) {
        orderData.status = values.status
      }
      let orderStatusChange = await changeOrderStausDataApi(orderData)

      if (orderStatusChange && orderStatusChange.status == 200) {
        Toaster({
          type: 'success',
          title: 'Vendor Order',
          description: 'Updated Successfully',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props
    console.log(data)
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={
                  data && data[0]?.status === 'SHIPPED'
                    ? {
                        status: 'SHIPPED',
                        dollar_rate: 0,
                      }
                    : {
                        status: 'PENDING',
                        dollar_rate: 0,
                      }
                }
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.dollar_rate,
                              id: 'dollar_rate',
                              name: 'dollar_rate',
                            }}
                            placeholder='Enter Dollar Rate'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('dollar_rate', value)
                            }}
                            onBlur={handleBlur}
                            label='Dollar Rate'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div> */}
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputSingleSelect
                            input={{
                              id: 'status',
                              name: 'status',
                              value: values.status,
                              options:
                                data && data[0]?.status === 'SHIPPED'
                                  ? [
                                      {
                                        label: 'Shipped',
                                        value: 'SHIPPED',
                                      },
                                      {
                                        label: 'Received',
                                        value: 'DELIVERED',
                                      },
                                      // {
                                      //   label: 'Paid',
                                      //   value: 'PAID',
                                      // },
                                    ]
                                  : [
                                      {
                                        label: 'Pending',
                                        value: 'PENDING',
                                      },
                                      {
                                        label: 'Shipped',
                                        value: 'SHIPPED',
                                      },
                                    ],
                            }}
                            placeholder='Select Status'
                            onChangeMethod={(value: string) => {
                              setFieldValue('status', value)
                            }}
                            label='Status'
                            clearable={false}
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
