import React from 'react'
import {config} from '../../../comman/constants'

let host = config.host
let port = '21'
const FtpDetails = (props: any) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div className='row' style={{marginBottom: '20px'}}>
            <div
              className='col-sm-6 col-12'
              style={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                color: '#000',
              }}
            >
              FTP Username :
            </div>
            <div className='col-sm-6 col-12' style={{}}>
              {props.data && props.data.ftp_username ? props.data.ftp_username : '-'}
            </div>
          </div>
          <div className='row' style={{marginBottom: '20px'}}>
            <div
              className='col-sm-6 col-12'
              style={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                color: '#000',
              }}
            >
              Host :
            </div>
            <div className='col-sm-6 col-12' style={{}}>
              {' '}
              {host ? host : ''}
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12'>
          <div className='row' style={{marginBottom: '20px'}}>
            <div
              className='col-sm-6 col-12'
              style={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                color: '#000',
              }}
            >
              FTP Password :
            </div>
            <div className='col-sm-6 col-12' style={{}}>
              {' '}
              {props.data && props.data.ftp_password ? props.data.ftp_password : '-'}
            </div>
          </div>
          <div className='row' style={{marginBottom: '20px'}}>
            <div
              className='col-sm-6 col-12'
              style={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                color: '#000',
              }}
            >
              Port :
            </div>
            <div className='col-sm-6 col-12' style={{}}>
              {' '}
              {port ? port : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FtpDetails
