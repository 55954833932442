import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {Tag} from 'antd'
// import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {editBuyRequestData, getBuyRequestStockDetails} from '../redux/BuyRequestCRUD'
import {Popconfirm} from 'antd'
import {Spin} from 'antd'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Tooltip} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Switch} from 'antd'
import {connect} from 'react-redux'
import {config} from './../../../comman/constants'
import ReactToPrint from 'react-to-print'
import PrintBuyRequestPreviewComponent from './PrintBuyRequestPreviewComponent'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import SuggestStockList from './SuggestStockList'

interface IRenderFormikForm {
  values: IBuyRequestFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

const PRIMARY_COLOR = config.buttonPrimaryColor

export interface IBuyRequestFormValues {
  status?: string
  diamonds?: {stock_id: number; is_available: boolean}
}

interface IEditBuyRequestProps extends RouteComponentProps<any> {
  onSubmit: any
  data: any
  handleCancel?: any
  loginUserData: any
}

class BuyRequestPreviewPage extends React.Component<IEditBuyRequestProps, any> {
  public componentRef: React.RefObject<any>
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      updatedStockData: [],
      clientDataState: {},
      shipmentAddressDataState: {},
      billingAddressDataState: {},
      approveButtonState: false,
      updateButtonState: false,
      apiResonseData: null,
      suggestStockModalState: null,
      suggestedStocks: [],
      mapObject: {},
    }
    this.componentRef = React.createRef<typeof PrintBuyRequestPreviewComponent>()
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  buyRequestStatus = [
    {label: 'Accepted', value: 'ACCEPTED'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Updated', value: 'UPDATED'},
    {label: 'Pending', value: 'PENDING'},
  ]

  stockAvailableStatus = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]

  public validateForm = (values: IBuyRequestFormValues) => {
    const errors: any = {}

    console.log(errors)

    return errors
  }

  public handleSubmit = (values: IBuyRequestFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    values.diamonds = this.state.updatedStockData
    onSubmit(values, data._id)
    handleCancel()

    this.setState({
      visible: false,
      shipmentData: [],
    })
  }

  componentDidMount() {
    this.callInitialData()
    // await this.initialStockStatus()
  }

  initialStockStatus = () => {
    const {data} = this.props

    // if (data && data.stock_diamonds.length >= 1) {
    //   let tempStockData: any = []
    //   data.stock_diamonds.map((stockItem: any, index: number) => {
    //     tempStockData.push({
    //       _id: stockItem._id,
    //       is_available: stockItem.is_available,
    //       stock_id: stockItem.stock_id.stock_id,
    //       stockId_id: stockItem.stock_id._id,
    //       stock_data: stockItem.stock_id,
    //     })
    //   })
    //   this.setState({updatedStockData: tempStockData})
    // }
  }

  public showStockSuggestModal = async (item: any) => {
    this.setState({
      suggestStockModalState: item,
    })
  }

  public closeStockSuggestModal = async () => {
    this.setState({
      suggestStockModalState: null,
    })
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      const {data} = this.props
      // let shipMentList = await getShipMentPriceList()
      // if (shipMentList.data) {
      //   this.setState({shipmentData: shipMentList.data})
      // }
      this.setState({
        isLoading: true,
      })
      const {match} = this.props
      const itemId = match.params.id

      let stockDetails: any = await getBuyRequestStockDetails(itemId)

      if (
        stockDetails &&
        stockDetails.data &&
        stockDetails.data.stock_ids &&
        stockDetails.data.stock_ids.length
      ) {
        // let tempStockData: any = []
        // stockDetails.data.stock_ids.map((stockItem: any, index: number) => {
        //   tempStockData.push({
        //     _id: stockItem.stock_id,
        //     is_available: stockItem.is_available,
        //     stock_id: stockItem.stock.stock_id,
        //     // stockId_id: stockItem.stock_id._id,
        //     stock_data: stockItem.stock.stock_id,
        //   })
        // })
        const allApproved = stockDetails.data.stock_ids.every(
          (stock: any) =>
            (stock.vendor_id && stock.is_action_taken && stock.is_available) ||
            (!stock.vendor_id && stock.is_available)
        )

        const updateButtonStatus = stockDetails.data.stock_ids.every(
          (stock: any) => stock.is_available
        )

        this.setState({
          apiResonseData: stockDetails.data,
          updatedStockData: stockDetails.data.stock_ids,
          shipmentData:
            stockDetails.data && stockDetails.data.shipment_price
              ? stockDetails.data.shipment_price
              : 0,
          clientDataState: stockDetails.data.user ? stockDetails.data.user : {},
          shipmentAddressDataState: stockDetails.data.shipping_address
            ? stockDetails.data.shipping_address
            : {},
          billingAddressDataState: stockDetails.data.billing_address
            ? stockDetails.data.billing_address
            : {},
          approveButtonState: allApproved,
          updateButtonState: updateButtonStatus,
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public updateBuyRequest = async (status: any) => {
    const {history} = this.props
    const {updatedStockData} = this.state
    try {
      this.setState({
        isLoading: true,
      })
      if (updatedStockData && updatedStockData.length >= 1) {
        let formValues: any = {}

        // formValues.status = status

        // if (status === 'UPDATED') {
        let finalCartValue: any = []

        console.log(updatedStockData, 'updatedStockData')
        updatedStockData.map((item: any) => {
          const id = item.vendor_id ? item.vendor_id : item.admin_id

          const suggestedStock = status === 'UPDATED' ? item.suggested_stock : {}

          const cartItem: any = {
            stock_id: item.stock_id,
            [item.vendor_id ? 'vendor_id' : 'admin_id']: id,
            is_available: item.is_available,
            is_action_taken: item.is_action_taken,
          }

          // Check if suggestedStock is neither null nor an empty object
          if (suggestedStock && Object.keys(suggestedStock).length > 0) {
            cartItem.suggested_stock = suggestedStock
          }

          finalCartValue.push(cartItem)
          // finalCartValue.push({
          //   stock_id: item.stock_id,
          //   [item.vendor_id ? 'vendor_id' : 'admin_id']: id,
          //   is_available: item.is_available,
          //   is_action_taken: item.is_action_taken,
          //   suggested_stock: status === 'UPDATED' ? item.suggested_stock : {},
          // })
        })
        // formValues.diamonds = finalCartValue
        // }

        // if (status === 'PENDING') {
        //   let finalCartValue: any = []

        //   updatedStockData
        //     .filter((item: any) => item.is_available)
        //     .map((item: any) =>
        //       finalCartValue.push({stock_id: item.stock_id, is_available: item.is_available})
        //     )
        //   formValues.diamonds = finalCartValue
        // }

        console.log('finalCartValue', finalCartValue)
        const {match} = this.props
        const itemId = match.params.id

        //   onSubmit(finalCartValue, itemId, status)
        let editResponse = await editBuyRequestData(finalCartValue, itemId, status)

        if (editResponse && editResponse.status == 200) {
          Toaster({
            type: 'success',
            title: 'BUY REQUEST',
            description: 'Updated SuccessFully',
          })
        } else {
          Toaster({
            type: 'error',
            title: 'BUY REQUEST',
            description: 'Something Went Wrong',
          })
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        isLoading: false,
      })
      history.push(`${process.env.PUBLIC_URL}/buy-request/list`)
    }
    // handleCancel()

    // this.setState({
    //   visible: false,
    // })
  }

  public navigateToPage = (path: string) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}${path}`)
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public onChangeStockUpdate = (stockId: string, status: string) => {
    let dataExistsIndex = -1
    if (this.state.updatedStockData && this.state.updatedStockData.length >= 1) {
      dataExistsIndex = this.state.updatedStockData.findIndex(
        (item: any) => item.stock_id === stockId
      )
    }
    if (dataExistsIndex === -1) {
      this.setState({
        updatedStockData: [...(this.state.updatedStockData || {}), {is_available: status}],
      })
    } else if (dataExistsIndex >= 0) {
      this.state.updatedStockData[dataExistsIndex].is_available = status

      const updateButtonStatus = this.state.updatedStockData.every(
        (stock: any) => stock.is_available
      )

      const allApproved = this.state.updatedStockData.every(
        (stock: any) =>
          (stock.vendor_id && stock.is_action_taken && stock.is_available) ||
          (!stock.vendor_id && stock.is_available)
      )

      this.setState({
        updatedStockData: [...this.state.updatedStockData],
        updateButtonState: updateButtonStatus,
        approveButtonState: allApproved,
      })
    }
  }

  public onEditSubmit = (stockId: string, values: any, suggestStockInformation: any) => {
    try {
      let dataExistsIndex = -1
      let prevSuggestedStockId: any = null
      if (this.state.updatedStockData && this.state.updatedStockData.length >= 1) {
        dataExistsIndex = this.state.updatedStockData.findIndex(
          (item: any) => item.stock_id === stockId
        )
      }
      if (dataExistsIndex >= 0) {
        // Remove previous suggestion from suggestedStocks if exists
        prevSuggestedStockId =
          this.state.updatedStockData[dataExistsIndex].suggested_stock &&
          this.state.updatedStockData[dataExistsIndex].suggested_stock.stock_id
            ? this.state.updatedStockData[dataExistsIndex].suggested_stock.stock_id
            : ''
        if (prevSuggestedStockId) {
          this.setState((prevState: any) => ({
            suggestedStocks: prevState.suggestedStocks.filter(
              (id: any) => id !== prevSuggestedStockId
            ),
          }))
        }

        // add suggested_stocks key and its values at found index
        this.state.updatedStockData[dataExistsIndex].suggested_stock = values

        // this.setState({
        //   updatedStockData: [...this.state.updatedStockData],
        // })

        this.setState((prevState: any) => ({
          updatedStockData: [...this.state.updatedStockData],
          suggestedStocks: [...prevState.suggestedStocks, values.stock_id], // Add new suggested stock
          mapObject: {
            ...prevState.mapObject,
            [stockId]: suggestStockInformation, // Update mapObject with new suggestion
          },
        }))
        console.log(suggestStockInformation)
      }
      Toaster({
        type: 'success',
        title: 'Suggested Stocks',
        description: 'suggested stocks added.',
      })
    } catch (e) {
      console.log(e, 'error in adding suggested stocks')
    }
  }

  public calculateTotal = () => {
    const {data} = this.props
    const {updatedStockData, mapObject} = this.state

    // if (data && data.stock_diamonds && data.stock_diamonds.length >= 1) {
    //   this.totalPrice = 0
    //   this.totalDiscount = 0
    //   data.stock_diamonds.map((invItem: any) => {
    //     this.totalPrice += invItem.stock_id.final_price
    //     this.totalDiscount += invItem.stock_id.discounts
    //   })
    // }
    // if (updatedStockData && updatedStockData.length >= 1) {
    //   this.totalPrice = 0
    //   this.totalDiscount = 0
    //   updatedStockData.map((invItem: any) => {
    //     this.totalPrice += invItem.stock.final_price
    //     this.totalDiscount += invItem.stock.discounts
    //   })
    // }
    this.totalPrice = 0
    this.totalDiscount = 0
    updatedStockData.forEach((invItem: any) => {
      console.log(invItem, 'invitem')
      if (invItem.is_available) {
        // Include the price of available stocks
        this.totalPrice += invItem.stock.final_price
        this.totalDiscount += invItem.stock.discounts
      } else if (!invItem.is_available && invItem.suggested_stock) {
        // Include suggested stock price if not available and suggested

        if (invItem && invItem.stock_id && mapObject[invItem.stock_id]) {
          this.totalPrice += mapObject[invItem.stock_id].final_price
          this.totalDiscount += mapObject[invItem.stock_id].discounts
        }
      }
    })
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {updatedStockData} = this.state

    // return returnPriceInThousandFormat(this.totalDiscount / data.stock_diamonds.length)
    return returnPriceInThousandFormat(this.totalDiscount / updatedStockData.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    // if (shipmentData && shipmentData.length >= 1) {
    //   let getShipMentData = shipmentData.find(
    //     (shipItem: any) =>
    //       shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
    //   )
    //   if (getShipMentData) {
    //     this.shipMentPrice = getShipMentData.price
    //   }
    // }
    this.shipMentPrice = shipmentData ? shipmentData : 0
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public render() {
    const {loginUserData} = this.props
    const {
      updatedStockData,
      clientDataState,
      shipmentAddressDataState,
      billingAddressDataState,
      approveButtonState,
      apiResonseData,
      updateButtonState,
      suggestStockModalState,
      suggestedStocks,
      mapObject,
      stockDetails,
    } = this.state

    const {role} = loginUserData
    console.log(updatedStockData, 'updatedStockData')
    return (
      <div className='card card-custom'>
        <Formik initialValues={{}} validate={this.validateForm} onSubmit={this.handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }: IRenderFormikForm) => {
            return (
              <Spin spinning={this.state.isLoading} tip='Loading...'>
                <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  <div className='card-body'>
                    <>
                      {role !== 'vendor' && (
                        <div className='mb-6' style={{position: 'relative'}}>
                          <h3
                            className='font-weight-bold'
                            style={{marginBottom: '0', lineHeight: '150%'}}
                          >
                            {/* {'Buy Request Details'} */}
                            {clientDataState
                              ? clientDataState?.legal_registered_name
                                ? clientDataState.legal_registered_name.toUpperCase()
                                : '-'
                              : '-'}
                          </h3>
                          <span style={{fontWeight: 500}}>
                            {clientDataState
                              ? clientDataState?.first_name && clientDataState?.last_name
                                ? `${clientDataState.first_name} ${clientDataState.last_name}`
                                : clientDataState.first_name
                                ? clientDataState.first_name
                                : '-'
                              : '-'}
                            {' | '}
                            {clientDataState
                              ? clientDataState?.email
                                ? clientDataState.email
                                : '-'
                              : '-'}
                          </span>
                          <div
                            className='right-side-details'
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                            }}
                          >
                            <div>
                              {' '}
                              <h3
                                className='font-weight-bold text-right'
                                style={{marginBottom: '0', lineHeight: '150%'}}
                              >
                                Buy Request
                              </h3>
                            </div>
                            <span style={{fontWeight: 500}}>
                              {' '}
                              {apiResonseData && apiResonseData.order_code
                                ? apiResonseData.order_code
                                : '-'}
                              {' | '}
                              {apiResonseData && apiResonseData.createdAt
                                ? moment(apiResonseData.createdAt).format('DD-MM-YYYY')
                                : '-'}
                            </span>
                          </div>
                          {/* <span className='ml-5'>
                              {data?.status && (
                                <Tag
                                  color={
                                    config.statusTagColor[data.status]
                                      ? config.statusTagColor[data.status]
                                      : 'default'
                                  }
                                >
                                  {data.status}
                                </Tag>
                              )}
                            </span> */}

                          {/* <div className='row mb-5'>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Name:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.first_name && clientDataState?.last_name
                                  ? `${clientDataState.first_name} ${clientDataState.last_name}`
                                  : clientDataState.first_name
                                  ? clientDataState.first_name
                                  : '-'
                                : '-'}
                            </div>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Company:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.legal_registered_name
                                  ? clientDataState.legal_registered_name
                                  : '-'
                                : '-'}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Email:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.email
                                  ? clientDataState.email
                                  : '-'
                                : '-'}
                            </div>
                          </div> */}
                        </div>
                      )}
                      <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                        <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                          <h3 className='mb-2'>{'Billing Address'}</h3>
                          <span>
                            {billingAddressDataState && (
                              <span
                                className='text-dark font-weight-normal'
                                style={{fontSize: '14px'}}
                              >
                                {billingAddressDataState.address_line_one
                                  ? ` ${billingAddressDataState.address_line_one},`
                                  : ''}
                                {billingAddressDataState.address_line_two
                                  ? ` ${billingAddressDataState.address_line_two},`
                                  : ''}
                                <br />
                                {billingAddressDataState.city
                                  ? ` ${billingAddressDataState.city},`
                                  : ''}
                                {billingAddressDataState.state
                                  ? ` ${billingAddressDataState.state},`
                                  : ''}
                                <br />
                                {billingAddressDataState.country
                                  ? ` ${billingAddressDataState.country} -`
                                  : ''}
                                {billingAddressDataState.zip_code
                                  ? ` ${billingAddressDataState.zip_code}`
                                  : ''}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                          <h3 className='mb-2'>{'Shipping Address'}</h3>
                          <span>
                            {shipmentAddressDataState && (
                              <div
                                className='text-dark font-weight-normal'
                                style={{fontSize: '14px'}}
                              >
                                {shipmentAddressDataState.address_line_one
                                  ? ` ${shipmentAddressDataState.address_line_one},`
                                  : ''}
                                {shipmentAddressDataState.address_line_two
                                  ? ` ${shipmentAddressDataState.address_line_two},`
                                  : ''}
                                {/* {shipmentAddressDataState.street
                              ? ` ${shipmentAddressDataState.street},`
                              : ''} */}
                                <br />
                                {shipmentAddressDataState.city
                                  ? ` ${shipmentAddressDataState.city},`
                                  : ''}
                                {shipmentAddressDataState.state
                                  ? ` ${shipmentAddressDataState.state},`
                                  : ''}
                                <br />
                                {shipmentAddressDataState.country
                                  ? ` ${shipmentAddressDataState.country} -`
                                  : ''}
                                {shipmentAddressDataState.zip_code
                                  ? ` ${shipmentAddressDataState.zip_code}`
                                  : ''}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className='row border-bottom pt-4'
                        style={{borderTop: '1px solid #eff2f5'}}
                      >
                        <h3 className='mb-10'>{'Stock Details'}</h3>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          {'Stock Id'}
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          {'Vendor'}
                        </div>
                        <div className='col-lg-4 text-dark font-weight-bold text-muted'>
                          {'Details'}
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Discount'}</div>
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'$/Ct'}</div>
                        </div>
                        {/* <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Price'}</div>
                        </div> */}
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Action Taken'}</div>
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                          <div className='text-center'>{'Availability'}</div>
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Price'}</div>
                        </div>
                      </div>
                      <div style={{maxHeight: '100vh', overflowX: 'hidden', overflowY: 'auto'}}>
                        {updatedStockData.map(
                          (invItem: any, index: number) =>
                            invItem && (
                              <>
                                <div
                                  className='row mt-10'
                                  style={{position: 'relative'}}
                                  key={index}
                                >
                                  {!invItem.is_available &&
                                    mapObject &&
                                    mapObject[invItem.stock_id] && (
                                      <div
                                        className='border-dark border-bottom'
                                        style={{position: 'absolute', top: '11px', zIndex: 2}}
                                      ></div>
                                    )}
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <label>
                                      <a
                                        href={`/inventory/preview/${invItem.stock.stock_id}?role=${
                                          invItem.stock.vendor_id ? 'vendor' : 'admin'
                                        }&id=${invItem.stock.vendor_id || invItem.stock.admin_id}`}
                                        target='_blank'
                                      >
                                        {/* {returnStockId(invItem.stock_id)} */}
                                        {invItem.stock && invItem.stock.stock_id
                                          ? returnStockId(invItem.stock.stock_id)
                                          : '-'}
                                      </a>
                                    </label>
                                  </div>
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <label>
                                      {invItem.vendor
                                        ? invItem.vendor?.first_name && invItem.vendor?.last_name
                                          ? `${invItem.vendor?.first_name} ${invItem.vendor?.last_name}`
                                          : invItem.vendor?.first_name
                                          ? invItem.vendor?.first_name
                                          : '-'
                                        : '-'}
                                    </label>
                                  </div>
                                  <div className='col-lg-4 text-dark font-weight-bold'>
                                    <label>{returnStockString(invItem.stock)}</label>
                                  </div>
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <div className='text-right'>
                                      <label>{invItem.stock.discounts}</label>
                                    </div>
                                  </div>
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <div className='text-right'>
                                      <label>{invItem.stock.price_per_caret}</label>
                                    </div>
                                  </div>
                                  {/* <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>
                                      {returnPriceInThousandFormatWithCurrencySign(
                                        invItem.stock.final_price
                                      )}
                                    </label>
                                  </div>
                                </div> */}
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <div className='text-right'>
                                      <label>
                                        {invItem && invItem.is_action_taken ? (
                                          <Tag color='success'>Yes</Tag>
                                        ) : (
                                          <Tag color='red'>No</Tag>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className='col-lg-2 text-dark font-weight-bold'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      gap: '10px',
                                      position: 'relative',
                                    }}
                                  >
                                    {/* <InputSingleSelect
                                    input={{
                                      value: invItem.is_available,
                                      id: 'status',
                                      name: 'status',
                                      options: this.stockAvailableStatus,
                                    }}
                                    placeholder='Stock Status'
                                    onChangeMethod={(value) => {
                                      this.onChangeStockUpdate(invItem.stock_id, value)
                                    }}
                                    disabled={invItem.vendor_id ? true : false }
                                    // label='Stock Status'
                                    error={errors}
                                    touched={touched}
                                    clearable={false}
                                    menuPosition='fixed'
                                    required={false}
                                  /> */}
                                    <Switch
                                      defaultChecked={invItem.is_available}
                                      checked={invItem.is_available}
                                      style={{
                                        backgroundColor: invItem.is_available
                                          ? PRIMARY_COLOR
                                          : '#bababa',
                                      }}
                                      disabled={invItem.vendor_id ? true : false}
                                      onChange={(value: any) => {
                                        this.onChangeStockUpdate(invItem.stock_id, value)
                                      }}
                                    />
                                    {/* {!invItem.is_available && (
                                    <button
                                      type='button'
                                      // className='btn btn-sm'
                                      onClick={() => this.showStockSuggestModal(invItem)}
                                      style={{
                                        cursor: 'pointer',
                                        border: 'none',
                                        backgroundColor: '#108ee9',
                                        color: 'white',
                                        position: 'absolute',
                                        top: '-25px',
                                      }}
                                    >
                                      {'Suggest Stocks'}
                                    </button>
                                  )} */}
                                  </div>
                                  <div className='col-lg-1 text-dark font-weight-bold'>
                                    <div className='text-right'>
                                      <label>
                                        {returnPriceInThousandFormatWithCurrencySign(
                                          invItem.stock.final_price
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {!invItem.is_available && mapObject && mapObject[invItem.stock_id] && (
                                  <div className='row row pb-4 justify-content-between'>
                                    {/* <div className='col-lg-12'>
                                      <span style={{width: '175px'}}>
                                        <span className='font-weight-bold' style={{color: 'green'}}>
                                          SUGGESTED STOCK :
                                        </span>
                                        <span className='text-muted font-weight-bold ml-1 mr-4'>
                                          {mapObject[invItem.stock_id] ? mapObject[invItem.stock_id].stock_id : '-'}
                                        </span>
                                      </span>
                                      <button
                                        type='button'
                                        onClick={() => this.showStockSuggestModal(invItem)}
                                        style={{
                                          cursor: 'pointer',
                                          border: 'none',
                                          backgroundColor: '#b7eb8f9e',
                                          color: 'green',
                                          padding: '4px 12px',
                                          borderRadius: '4px',
                                          fontWeight: 600,
                                        }}
                                      >
                                        {'Suggest Stocks'}
                                      </button>
                                    </div> */}
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <label>
                                        <a
                                          href={`/inventory/preview/${
                                            mapObject[invItem.stock_id].stock_id
                                          }`}
                                          target='_blank'
                                        >
                                          {mapObject &&
                                          mapObject[invItem.stock_id] &&
                                          mapObject[invItem.stock_id].stock_id
                                            ? returnStockId(mapObject[invItem.stock_id].stock_id)
                                            : '-'}
                                        </a>
                                      </label>
                                    </div>
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <label>
                                        {/* {invItem.vendor
                                          ? invItem.vendor?.first_name && invItem.vendor?.last_name
                                            ? `${invItem.vendor?.first_name} ${invItem.vendor?.last_name}`
                                            : invItem.vendor?.first_name
                                            ? invItem.vendor?.first_name
                                            : '-'
                                          : '-'} */}
                                        {mapObject &&
                                        mapObject[invItem.stock_id] &&
                                        mapObject[invItem.stock_id].vendor_details
                                          ? mapObject[invItem.stock_id].vendor_details.first_name &&
                                            mapObject[invItem.stock_id].vendor_details.last_name
                                            ? `${
                                                mapObject[invItem.stock_id].vendor_details
                                                  .first_name
                                              } ${
                                                mapObject[invItem.stock_id].vendor_details.last_name
                                              }`
                                            : mapObject[invItem.stock_id].vendor_details.first_name
                                            ? mapObject[invItem.stock_id].vendor_details.first_name
                                            : '-'
                                          : '-'}
                                      </label>
                                    </div>
                                    <div className='col-lg-4 text-dark font-weight-bold'>
                                      <label>
                                        {mapObject && mapObject[invItem.stock_id]
                                          ? `${
                                              mapObject[invItem.stock_id].shape
                                                ? mapObject[invItem.stock_id].shape
                                                : '-'
                                            } |
                                        ${
                                          mapObject[invItem.stock_id].weight
                                            ? mapObject[invItem.stock_id].weight
                                            : '-'
                                        } | 
                                        ${
                                          mapObject[invItem.stock_id].color
                                            ? mapObject[invItem.stock_id].shape
                                            : '-'
                                        } | 
                                        ${
                                          mapObject[invItem.stock_id].clarity
                                            ? mapObject[invItem.stock_id].clarity
                                            : '-'
                                        } | 
                                        ${
                                          mapObject[invItem.stock_id].cut
                                            ? mapObject[invItem.stock_id].cut
                                            : '-'
                                        } |
                                        ${
                                          mapObject[invItem.stock_id].polish
                                            ? mapObject[invItem.stock_id].polish
                                            : '-'
                                        } |
                                        ${
                                          mapObject[invItem.stock_id].symmetry
                                            ? mapObject[invItem.stock_id].symmetry
                                            : '-'
                                        } |
                                        `
                                          : '-'}
                                      </label>
                                    </div>
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <div className='text-right'>
                                        <label>
                                          {' '}
                                          {mapObject && mapObject[invItem.stock_id]
                                            ? mapObject[invItem.stock_id].discounts
                                              ? mapObject[invItem.stock_id].discounts
                                              : '-'
                                            : '-'}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <div className='text-right'>
                                        <label>
                                          {' '}
                                          {mapObject && mapObject[invItem.stock_id]
                                            ? mapObject[invItem.stock_id].price_per_caret
                                              ? mapObject[invItem.stock_id].price_per_caret
                                              : '-'
                                            : '-'}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <div className='text-right'>
                                        <label>
                                          {invItem && invItem.is_action_taken ? (
                                            <Tag color='success'>Yes</Tag>
                                          ) : (
                                            <Tag color='red'>No</Tag>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      className='col-lg-2 text-dark font-weight-bold'
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: '10px',
                                        position: 'relative',
                                      }}
                                    >
                                      <Switch
                                        defaultChecked={true}
                                        checked={true}
                                        style={{
                                          backgroundColor: invItem.is_available
                                            ? PRIMARY_COLOR
                                            : '#bababa',
                                        }}
                                        disabled={true}
                                        onChange={(value: any) => {
                                          // this.onChangeStockUpdate(invItem.stock_id, value)
                                        }}
                                      />
                                    </div>
                                    <div className='col-lg-1 text-dark font-weight-bold'>
                                      <div className='text-right'>
                                        <label>
                                          {mapObject &&
                                          mapObject[invItem.stock_id] &&
                                          mapObject[invItem.stock_id].final_price
                                            ? returnPriceInThousandFormatWithCurrencySign(
                                                mapObject[invItem.stock_id].final_price
                                              )
                                            : '-'}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!invItem.is_available && (
                                  <div className='row row border-bottom pb-4 justify-content-between'>
                                    <div className='col-lg-12'>
                                      <button
                                        type='button'
                                        onClick={() => this.showStockSuggestModal(invItem)}
                                        style={{
                                          cursor: 'pointer',
                                          border: 'none',
                                          backgroundColor: '#b7eb8f9e',
                                          color: 'green',
                                          padding: '4px 12px',
                                          borderRadius: '4px',
                                          fontWeight: 600,
                                        }}
                                      >
                                        {'Suggest Stocks'}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </>
                            )
                        )}
                      </div>
                      <div className='row border-top pt-6'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Sub Total:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{this.calculateTotal()}</div>
                        </div>
                      </div>
                      {/* <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                          {'Discount:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{`${this.calculateAvgDiscount()}(%)`}</div>
                        </div>
                      </div> */}
                      <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Shipment Price:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{this.calculateShipMent()}</div>
                        </div>
                      </div>
                      <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Tax Price:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>
                            {apiResonseData && apiResonseData.tax_price
                              ? returnPriceInThousandFormatWithCurrencySign(
                                  apiResonseData.tax_price
                                )
                              : '0'}
                          </div>
                        </div>
                      </div>
                      <div className='row border-bottom mt-5 pb-6'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Total:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          {/* <div className='text-right'>{this.calculateGrandTotal()}</div> */}
                          <div className='text-right'>
                            {apiResonseData && apiResonseData.grand_total
                              ? returnPriceInThousandFormatWithCurrencySign(
                                  apiResonseData.grand_total
                                )
                              : '0'}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  {/* <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-10 ml-2 text-muted'>
                      <h3 className='mb-2'>{'Billing Address'}</h3>
                      <span>
                        {billingAddressDataState && (
                          <span
                            className='text-dark font-weight-normal text-muted'
                            style={{fontSize: '14px'}}
                          >
                            {billingAddressDataState.address_line_one
                              ? ` ${billingAddressDataState.address_line_one},`
                              : ''}
                            {billingAddressDataState.address_line_two
                              ? ` ${billingAddressDataState.address_line_two},`
                              : ''}
                            {billingAddressDataState.city
                              ? ` ${billingAddressDataState.city},`
                              : ''}
                            {billingAddressDataState.state
                              ? ` ${billingAddressDataState.state},`
                              : ''}
                            {billingAddressDataState.country
                              ? ` ${billingAddressDataState.country} -`
                              : ''}
                            {billingAddressDataState.zip_code
                              ? ` ${billingAddressDataState.zip_code}`
                              : ''}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 mb-10'>
                      <h3 className='mb-2'>{'Shipping Address'}</h3>
                      <span>
                        {shipmentAddressDataState && (
                          <div
                            className='text-dark font-weight-normal text-muted'
                            style={{fontSize: '14px'}}
                          >
                            {shipmentAddressDataState.address_line_one
                              ? ` ${shipmentAddressDataState.address_line_one},`
                              : ''}
                            {shipmentAddressDataState.address_line_two
                              ? ` ${shipmentAddressDataState.address_line_two},`
                              : ''}
                            {shipmentAddressDataState.city
                              ? ` ${shipmentAddressDataState.city},`
                              : ''}
                            {shipmentAddressDataState.state
                              ? ` ${shipmentAddressDataState.state},`
                              : ''}
                            {shipmentAddressDataState.country
                              ? ` ${shipmentAddressDataState.country} -`
                              : ''}
                            {shipmentAddressDataState.zip_code
                              ? ` ${shipmentAddressDataState.zip_code}`
                              : ''}
                          </div>
                        )}
                      </span>
                    </div>
                  </div> */}
                  {role !== 'vendor' && apiResonseData && apiResonseData.status == 'PENDING' && (
                    <div className='card-footer' style={{marginBottom: '40px', border: 'none'}}>
                      <>
                        <div
                          className='float-right rounded mr-5'
                          style={{backgroundColor: `${approveButtonState ? '#50cd89' : '#e4e6ef'}`}}
                        >
                          <Tooltip
                            placement='top'
                            title={
                              approveButtonState
                                ? 'Approve'
                                : 'Some of the Diamonds may need Vendor Action'
                            }
                          >
                            <Popconfirm
                              onConfirm={() => {
                                this.updateBuyRequest('ACCEPTED')
                              }}
                              title='Are you sure you want to Approve Buy Request?'
                              disabled={approveButtonState ? false : true}
                            >
                              <button
                                type='button'
                                className='btn btn-sm'
                                // onClick={() => this.updateBuyRequest('ACCEPTED')}
                                disabled={approveButtonState ? false : true}
                                style={{
                                  cursor: `${approveButtonState ? 'pointer' : 'not-allowed'}`,
                                  border: 'none',
                                  backgroundColor: `${approveButtonState ? '#50cd89' : '#e4e6ef'}`,
                                  color: 'white',
                                }}
                              >
                                {'Approve'}
                              </button>
                            </Popconfirm>
                          </Tooltip>
                        </div>
                        <div className='float-right mr-5'>
                          <Popconfirm
                            onConfirm={() => {
                              this.updateBuyRequest('CANCELED')
                            }}
                            title='Are you sure you want to Reject Buy Request?'
                          >
                            <button
                              type='button'
                              className='btn btn-danger btn-sm'
                              // onClick={() => this.updateBuyRequest('CANCELED')}
                            >
                              {'Reject'}
                            </button>
                          </Popconfirm>
                        </div>
                        {/* <div className='float-right mr-5'>
                          <InputButtonComponent
                            disabled={approveButtonState ? true : false}
                            onClick={() => this.updateBuyRequest('UPDATED')}
                          >
                            {'Update'}
                          </InputButtonComponent>
                        </div> */}
                        <div
                          className='float-right rounded mr-5'
                          style={{backgroundColor: `${updateButtonState ? '#6c757d' : '#007bff'}`}}
                        >
                          <Popconfirm
                            onConfirm={() => {
                              this.updateBuyRequest('UPDATED')
                            }}
                            title='Are you sure you want to Update Buy Request?'
                            disabled={updateButtonState ? true : false}
                          >
                            <button
                              type='button'
                              className='btn btn-sm'
                              // onClick={() => this.updateBuyRequest('UPDATED')}
                              disabled={updateButtonState ? true : false}
                              style={{
                                cursor: `${updateButtonState ? 'not-allowed' : 'pointer'}`,
                                border: 'none',
                                backgroundColor: `${updateButtonState ? '#6c757d' : '#007bff'}`,
                                color: 'white',
                                opacity: 1,
                                pointerEvents: 'auto',
                              }}
                            >
                              {'Update'}
                            </button>
                          </Popconfirm>
                        </div>
                        <div className='float-right rounded mr-5'>
                          <button
                            type='button'
                            className='btn btn-sm'
                            onClick={() => this.navigateToPage('/buy-request/list')}
                            style={{
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: '#8B93FF',
                              color: 'white',
                            }}
                          >
                            {'Back'}
                          </button>
                        </div>
                        <ReactToPrint
                          trigger={() => (
                            <div className='float-right rounded mr-5'>
                              <button
                                type='button'
                                className='btn btn-sm bg-info'
                                style={{
                                  cursor: 'pointer',
                                  border: 'none',
                                  color: 'white',
                                }}
                              >
                                {'Print'}
                              </button>
                            </div>
                          )}
                          content={() => this.componentRef.current}
                        />
                        <div className='d-none'>
                          <PrintBuyRequestPreviewComponent
                            data={this.props.data}
                            onSubmit={this.props.onSubmit}
                            componentRef={this.componentRef}
                          />
                        </div>
                      </>
                    </div>
                  )}
                </div>
                <CommanModal
                  show={suggestStockModalState ? true : false}
                  handleClose={() => {
                    this.closeStockSuggestModal()
                  }}
                  modalSize={'modal-xl'}
                  title={'Suggest Stocks'}
                  submitText={'Save Changes'}
                  isScrollable={true}
                >
                  <div>
                    {suggestStockModalState && (
                      <SuggestStockList
                        data={suggestStockModalState}
                        onSubmit={(stockId: string, values: any, information: any) => {
                          this.onEditSubmit(stockId, values, information)
                          this.closeStockSuggestModal()
                        }}
                        handleCancel={() => {
                          this.closeStockSuggestModal()
                        }}
                        suggestedStocksState={suggestedStocks}
                        stockInforrmatiomMapObject={mapObject}
                      />
                    )}
                  </div>
                </CommanModal>
              </Spin>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUserData: state.auth.user,
})

const mapDispatchToProps = (dispatch: any) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BuyRequestPreviewPage))
