import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_VENDOR_ORDER_URL = `${API_URL}api/v1/auth/admin/vendor-order`
export const PREVIEW_ORDER_DETAILS = `${API_URL}api/v1/auth/admin/order-details`
export const EDIT_ORDER_DETAILS_URL = `${API_URL}api/v1/auth/admin/vendor-order`
export const GET_VENDOR_ORDER_GROUP_URL = `${API_URL}api/v1/auth/admin/vendor-order-group`
export const UPDATE_ORDER_INVOICE_URL = `${API_URL}api/v1/auth/admin/vendor-order-invoice`
export const INVOICE_VERIFY_STATUS_URL = `${API_URL}api/v1/auth/admin/accept-invoice`

interface ListType {
  count?: any
  data: any
}

// Get Order Data
export async function getVendorOrderData(startIndex: number, limitNumber: number, status?: string) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_VENDOR_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}`
    } else {
      getOrderAPIPath = `${GET_VENDOR_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&status=PENDING`
    }
    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_VENDOR_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function getVendorOrderStockDetails(req_id: string) {
  try {
    let orderDetailsData: ListType = await axios.get(`${PREVIEW_ORDER_DETAILS}?id=${req_id}`)
    return orderDetailsData
  } catch (e) {
    throw e
  }
}

export async function changeOrderStausDataApi(values: any) {
  let editUserData = await axios.put(`${EDIT_ORDER_DETAILS_URL}`, {
    ...values,
  })
  return editUserData
}
export async function getVendorOrdergroupData(
  startIndex: number,
  limitNumber: number,
  status?: string
) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_VENDOR_ORDER_GROUP_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}&sort_by_name=true`
    } else {
      getOrderAPIPath = `${GET_VENDOR_ORDER_GROUP_URL}?skip=${startIndex}&limit=${limitNumber}&status=PENDING&sort_by_name=true`
    }
    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_VENDOR_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function invoiceOrderApi(values: any) {
  let updateInvoiceData = await axios.put(`${UPDATE_ORDER_INVOICE_URL}`, {
    ...values,
  })
  return updateInvoiceData
}


export async function verifyInvoiceStatus(values: any) {
  try {
    console.log({values})
    let userKycData = await axios.put(`${INVOICE_VERIFY_STATUS_URL}`, {
      ...values,
    })
    return userKycData
  } catch (e) {
    throw e
  }
}