/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect, useState} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {VendorTermsForm} from '../component/vendorTermsCondition/VendorTermsForm'
import {getLoginUserData} from './redux/userCRUD'
import {KycUpload} from '../component/setting/containers/KycUpload'
import {KycPendingView} from '../component/vendorKyc/KycPendingView'
import {KycRejectedView} from '../component/vendorKyc/KycRejectedView'
import {Spin} from 'antd'

const Routes: FC = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [userData, setUserData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchKycData = async () => {
      try {
        setIsLoading(true)
        const {data} = await getLoginUserData()
        setUserData(data)
      } catch (error) {
        console.error('Error fetching KYC data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (isAuthorized) {
      fetchKycData()
    } else {
      setIsLoading(false)
    }
  }, [isAuthorized])


  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          {isLoading ? (
            <div>
              {' '}
              <Spin spinning={isLoading} tip='Loading...'></Spin>
            </div>
          ) : (
            <>
              {isAuthorized.role !== 'vendor' ? (
                // If role is not 'vendor', render PrivateRoutes directly
                <PrivateRoutes adminData={isAuthorized} />
              ) : (
                <>
                  {/* If role is 'vendor', check terms acceptance and KYC status */}
                  {!isAuthorized.is_terms_accepted ? (
                    <VendorTermsForm />
                  ) : (
                    <>
                      {userData && !userData.is_verified && !userData.is_kyc_uploaded ? (
                        <KycUpload />
                      ) : userData && userData.is_kyc_uploaded && !userData.is_verified ? (
                        userData.document_name ? (
                          <KycPendingView
                            data={userData}
                            handleCancel={() => {
                              // this.closeDocumentsModal()
                            }}
                          />
                        ) : (
                          <>
                            <KycRejectedView
                              data={userData}
                              handleCancel={() => {
                                // this.closeDocumentsModal()
                              }}
                            />
                            <KycUpload />
                          </>
                        )
                      ) : (
                        userData &&
                        userData.is_verified && <PrivateRoutes adminData={isAuthorized} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
