/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {changeEmailApi} from '../../../modules/auth/redux/AuthCRUD'
import {useHistory, withRouter} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {editVendorData} from '../../../modules/auth/redux/AuthCRUD'
import {connect, useSelector} from 'react-redux'
import {Tag} from 'antd'
import {config} from '../../../comman/constants'

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
interface KycUploadState {
  file_name: string
  data: any
  isLoading: boolean
  viewFileUploadModal: boolean
  uploadMedia: any
  isImageLinked: boolean
  image_url: string
  isVerified: boolean
  // Add other state properties here if needed
}
export class KycUpload extends React.Component<{}, KycUploadState> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
      isVerified: false,
    }
  }
  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia', fileName)
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isImageLinked: false,
      image_url: `${appAPIURL}banner/image/${fileName}`,
      file_name: fileName,
    })
  }

  public handleSubmit = async () => {
    try {
      // const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
      // console.log(user, 'admin')
      const authData = localStorage.getItem('persist:auth')
      if (authData) {
        const parsedData = JSON.parse(authData)
        console.log({parsedData})
        const user = parsedData?.user
        const parsedUserData = JSON.parse(user)

        console.log({parsedUserData})
        const filename = this.state.file_name
        console.log({filename})
        let changeData = await editVendorData({document_name: filename, id: parsedUserData.id})
        console.log({changeData})
        if (changeData.status === 200) {
          Toaster({
            type: 'success',
            title: 'File uploaded',
            description: 'File submitted Successfully',
          })
        }
      }
    } catch (error) {
      console.log({error})
    }
  }

  componentDidMount() {
    console.log('mount++')
    const authData = localStorage.getItem('persist:auth')
    if (authData) {
      const parsedData = JSON.parse(authData)
      const user = parsedData?.user
      const parsedUserData = JSON.parse(user)
      console.log({parsedUserData})
      this.setState({
        isVerified: parsedUserData.is_verified,
      })
    }
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>File Upload for KYC</h3>
          </div>
          <div className='card-body'>
            <div className='table-form'>
              <div>
                <InputSingleFileUpload
                  ref={(e: any) => {
                    //   this.viewFileUploadModalRef = e
                  }}
                  //   buttonLabel={'Choose Provider Image'}
                  onBlur={() => {}}
                  error={() => {}}
                  touched={() => {}}
                  //   required={false}
                  //   label='Item Image'
                  name='image_url'
                  input={{
                    id: 'document',
                    name: 'document',
                    action: `${appAPIURL}/file/upload/vendor/document`,
                    headers: {
                      authorization: 'authorized-text',
                    },
                    accept: 'png|jpeg|jpg|gif|webp|pdf|doc',
                    acceptMimeTypes: [
                      'image/png',
                      'image/jpeg',
                      'image/jpg',
                      'application/pdf',
                      'application/msword',
                    ],
                    mediaType: 'png|jpeg|jpg|gif|webp|pdf|doc',
                    mediaModuleType: 'png|jpeg|jpg|gif|webp|pdf|doc',
                  }}
                  onUpload={(fileName: string, FileData: Array<any>, customFileData: Object) => {
                    this.uploadMedia(fileName, FileData, customFileData)
                  }}
                />
              </div>
              {/* {console.log(this.state.image_url)} */}
              {this.state.image_url && this.state.file_name ? (
                <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md'>
                  <h5 className='text-lg font-semibold'>Preview :</h5>
                  <img
                    src={`${config.kyc_base_url}${this.state.file_name}`}
                    // src={`https://diamond-company.api.dharmatech.in/vendor/document/${this.state.file_name}`}
                    alt='Banner'
                    style={{
                      width: '350px',
                      height: '175px',
                      margin: '10px auto',
                      objectFit: 'contain',
                    }}
                  />{' '}
                  <p className='mt-1 text-sm text-gray-600'>
                    Please click on the Submit button for final submit.
                  </p>
                </div>
              ) : null}
              <div style={{marginBottom: '15px'}}></div>

              <div className='d-flex flex-wrap justify-content-start pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                  onClick={() => {
                    this.handleSubmit()
                  }}
                >
                  <span className='indicator-label'>Submit</span>
                  {/* {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// const mapStateToProps = (state: any) => ({
//   loginUser: state.auth.user,
//   bannerData: state.banner.bannerData,
//   bannerDataTotal: state.banner.bannerDataTotal,
//   bannerLoading: state.banner.bannerLoading,
// })

// export default connect(mapStateToProps)(withRouter(KycUpload))

// export {KycUpload}
