/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeStatusInventoryData,
  deleteInventoryData,
  //   editInventoryData,
  exportAllStockList,
  exportSelectedStockList,
  getInventoryData,
  getSearchInventoryData,
  editStockArrivalStatusData,
  editStockFeatureStatusData,
} from '../redux/InventoryCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {IInventoryFormValues} from './CreateInventoryForm'
import {Tag} from 'antd'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import {Spin} from 'antd'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey?: any
  inventoryData: any
  inventoryDataTotal: number
  inventoryLoading: boolean
  inventoryCartData: any
  searchParamsData: any
  setListInventoryData: (inventoryData: any) => void
  setListInventoryDataTotal: (inventoryDataTotal: number) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  setInventoryCartData: (inventoryCartData: any) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  inventorySelectedData: any
  dataTableSelectedRowKeys: any
}

type PathParamsType = {
  param1: string
}
class ListInventoryData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      inventorySelectedData: null,
      dataTableSelectedRowKeys: [],
    }
  }

  tempBuyRequests: any = []

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListInventoryData(pagination)
    )
  }

  public componentDidMount = () => {
    this.setInitialDataTableValue()
  }

  public setInitialDataTableValue = () => {
    const {inventoryCartData} = this.props
    if (inventoryCartData && inventoryCartData.length >= 1) {
      this.setState({
        dataTableSelectedRowKeys: inventoryCartData.map((item: any) => item.id),
      })
    }
  }

  public getListInventoryData = async (pagination: any) => {
    const {searchText} = this.state

    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listInventorySearchData(start, end)
        } else {
          this.listInventoryData(start, end)
        }
      }
    )
  }

  public listInventoryData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal} = this.props
      await setInventoryLoading(true)
      let inventoryData = await getInventoryData(start, end, false)
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data)
        await setListInventoryDataTotal(inventoryData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
      this.setState({
        isLoading: false,
      })
    }
  }

  public listInventorySearchData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {searchParamsData, setListInventoryData, setListInventoryDataTotal} = this.props
      const {searchText} = this.state

      await setInventoryLoading(true)
      let inventoryData = await getSearchInventoryData(
        start,
        end,
        searchParamsData,
        false,
        searchText
      )
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data.docs)
        await setListInventoryDataTotal(inventoryData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
      this.setState({
        isLoading: false,
      })
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listInventorySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateInventory = () => {
    const {history} = this.props
    history.push('/inventory/import')
  }

  public deleteInventory = async (item: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await deleteInventoryData(item.id)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await changeStatusInventoryData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onChangeFeatureStatus = async (item: any, checked: boolean) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await editStockFeatureStatusData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onChangeArrivalStatus = async (item: any, checked: boolean) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await editStockArrivalStatusData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  //   public onEditSubmit = async (values: IInventoryFormValues, inventoryId: string) => {
  //     const {setInventoryLoading} = this.props
  //     try {
  //       const {startIndex, limitNumber, searchText} = this.state

  //       await setInventoryLoading(true)
  //       await editInventoryData(values, inventoryId)

  //       Toaster({
  //         type: 'success',
  //         title: 'INVENTORY',
  //         description: 'Edited SuccessFully',
  //       })

  //       if (searchText != null && searchText !== '') {
  //         this.listInventorySearchData(startIndex, limitNumber)
  //         return
  //       }
  //       this.listInventoryData(startIndex, limitNumber)
  //     } catch (e) {
  //       console.log('!!!!Error', e)
  //     } finally {
  //       await setInventoryLoading(false)
  //     }
  //   }

  public showEditModal = async (item: any) => {
    this.setState({
      inventorySelectedData: item,
    })
  }

  public previewInventoryForm = async (item: any) => {
    const {history} = this.props
    history.push('/inventory/preview/' + item.id)
  }

  public closeEditModal = async () => {
    this.setState({
      inventorySelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    // fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a
              href={`/inventory/preview/${item.stock_id}?role=vendor&id=${item.vendor_id}`}
              target='_blank'
            >
              <strong>{item.stock_id ? returnStockId(item.stock_id) : '-'}</strong>
            </a>
          </div>
        )
      },
    },
    // {
    //   // title: 'Stock Id',
    //   title: 'Vendor',
    //   key: 'vendor_name',
    //   width: 80,
    //   // fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <a href={`/inventory/preview/${item.id}`} target='_blank'>
    //           <strong>{item.vendor_name ? item.vendor_name : '-'}</strong>
    //         </a>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Image',
      title: 'MEDIA',
      key: 'diamond_image',
      width: 150,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.diamond_image ? (
              <a className='text-muted' href={item.diamond_image} target='_blank'>
                {/* {item.diamond_image} */}
                image
              </a>
            ) : (
              <span className='text-muted'>{'No Image'}</span>
            )}
            {' | '}
            {item.diamond_video ? (
              <a className='text-muted' href={item.diamond_video} target='_blank'>
                {/* {item.diamond_video} */}
                video
              </a>
            ) : (
              <span className='text-muted'>{'No Video'}</span>
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape ? returnStockId(item.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.weight ? item.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? returnStockId(item.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? returnStockId(item.clarity) : '-'}</div>
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.polish ? returnStockId(item.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.symmetry ? returnStockId(item.symmetry) : '-'}</div>
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.fluorescence_intensity ? returnStockId(item.fluorescence_intensity) : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Lab Grown Type',
      title: 'LAB',
      // ellipsis: true,
      key: 'lab_grown_type',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.lab_grown_type ? item.lab_grown_type : '-'}</div>
      },
    },
    {
      // title: 'Certificate No.',
      title: 'CERTIFICATE',
      key: 'certificate_number',
      width: 100,
      // ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return <div>{item.certificate_number ? item.certificate_number : '-'}</div>
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        // return <div>{item.discounts ? item.discounts : '-'}</div>
        return (
          <div>
            {this.props.loginUser.role === 'vendor'
              ? item.discounts_ori || '-'
              : item.discounts || '-'}
          </div>
        )
      },
    },
    ...(this.props.loginUser.role !== 'vendor'
      ? [
          {
            // title: 'Discounts',
            title: 'DISCOUNTS ORI',
            // ellipsis: true,
            key: 'discounts_ori',
            width: 100,
            render: (text: any, item: any, index: number) => {
              return <div>{item.discounts_ori ? item.discounts_ori : '-'}</div>
            },
          },
        ]
      : []),
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {this.props.loginUser.role === 'vendor'
              ? item.price_per_caret_ori
                ? returnPriceInThousandFormatWithCurrencySign(item.price_per_caret_ori)
                : '-'
              : item.price_per_caret
              ? returnPriceInThousandFormatWithCurrencySign(item.price_per_caret)
              : '-'}
          </div>
        )
      },
    },
    ...(this.props.loginUser.role !== 'vendor'
      ? [
          {
            // title: 'Price Per Caret',
            title: '$/CT ORI',
            // ellipsis: true,
            key: 'price_per_caret_ori',
            width: 60,
            render: (text: any, item: any, index: number) => {
              return (
                <div>
                  {item.price_per_caret_ori
                    ? returnPriceInThousandFormatWithCurrencySign(item.price_per_caret_ori)
                    : '-'}
                </div>
              )
            },
          },
        ]
      : []),
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {this.props.loginUser.role === 'vendor'
              ? item.final_price_ori
                ? returnPriceInThousandFormatWithCurrencySign(item.final_price_ori)
                : '-'
              : item.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.final_price)
              : '-'}
          </div>
        )
      },
    },
    ...(this.props.loginUser.role !== 'vendor'
      ? [
          {
            title: 'FINAL PRICE ORI',
            key: 'final_price_ori',
            width: 130,
            render: (text: any, item: any, index: number) => {
              return (
                <div>
                  {item.final_price_ori
                    ? returnPriceInThousandFormatWithCurrencySign(item.final_price_ori)
                    : '-'}
                </div>
              )
            },
          },
        ]
      : []),
    // {
    //   // title: 'Availability',
    //   title: 'AVAILABILITY',
    //   // ellipsis: true,
    //   key: 'availability',
    //   width: 110,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.availability ? returnStockId(item.availability) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Brand',
    //   title: 'BRAND',
    //   key: 'brand',
    //   width: 105,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.brand ? returnStockId(item.brand) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Crown Angle',
    //   title: 'CROWN ANGLE',
    //   // ellipsis: true,
    //   key: 'crown_angle',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.crown_angle ? item.crown_angle : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Crown Height',
    //   title: 'CROWN HEIGHT',
    //   // ellipsis: true,
    //   key: 'crown_height',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.crown_height ? item.crown_height : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Culet Condition',
    //   title: 'CULET CONDITION',
    //   // ellipsis: true,
    //   key: 'culet_condition',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.culet_condition ? returnStockId(item.culet_condition) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Depth',
    //   title: 'DEPTH',
    //   key: 'depth',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.depth ? item.depth : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Type',
    //   title: 'TYPE',
    //   key: 'diamond_type',
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.diamond_type ? returnStockId(item.diamond_type) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Girdle Condition',
    //   title: 'GIRDLE CONDITION',
    //   // ellipsis: true,
    //   key: 'girdle_condition',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.girdle_condition ? returnStockId(item.girdle_condition) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Lw Ratio',
    //   title: 'LW RATIO',
    //   // ellipsis: true,
    //   key: 'lw_ratio',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.lw_ratio ? item.lw_ratio : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Meas Depth',
    //   title: 'MEAS DEPTH',
    //   // ellipsis: true,
    //   key: 'meas_depth',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.meas_depth ? item.meas_depth : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Measurements',
    //   title: 'MEASUREMENTS',
    //   // ellipsis: true,
    //   key: 'measurements',
    //   width: 140,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.measurements ? item.measurements : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Pavilion Angle',
    //   title: 'PAVILION ANGLE',
    //   // ellipsis: true,
    //   key: 'pavilion_angle',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.pavilion_angle ? item.pavilion_angle : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Pavilion Depth',
    //   title: 'PAVILION DEPTH',
    //   // ellipsis: true,
    //   key: 'pavilion_depth',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.pavilion_depth ? item.pavilion_depth : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Rap Per Caret',
    //   title: 'RAP PER CARET',
    //   // ellipsis: true,
    //   key: 'rap_per_caret',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>{item.rap_per_caret ? returnPriceInThousandFormat(item.rap_per_caret) : '-'}</div>
    //     )
    //   },
    // },
    // {
    //   // title: 'Rap Price',
    //   title: 'RAP PRICE',
    //   // ellipsis: true,
    //   key: 'rap_price',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.rap_price ? item.rap_price : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Table',
    //   title: 'TABLE',
    //   key: 'table',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.table ? item.table : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Treatment',
    //   title: 'TREATMENT',
    //   // ellipsis: true,
    //   key: 'treatment',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.treatment ? returnStockId(item.treatment) : '-'}</div>
    //   },
    // },
    // {
    //   title: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.updatedAt ? item.updatedAt : '-'}</div>
    //   },
    // },
    // {
    //   title: 'createdAt',
    //   key: 'createdAt',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.createdAt ? item.createdAt : '-'}</div>
    //   },
    // },
    {
      title: 'Feature',
      key: 'is_featured',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_featured != null ? (
              <Switch
                checked={item.is_featured}
                style={{
                  backgroundColor: item.is_featured ? PRIMARY_COLOR : '#bababa',
                }}
                onChange={(checked: boolean) => {
                  this.onChangeFeatureStatus(item, checked)
                }}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Arrival',
      key: 'is_new_arrival',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_new_arrival != null ? (
              <Switch
                checked={item.is_new_arrival}
                style={{
                  backgroundColor: item.is_new_arrival ? PRIMARY_COLOR : '#bababa',
                }}
                onChange={(checked: boolean) => {
                  this.onChangeArrivalStatus(item, checked)
                }}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    // {
    //   title: 'Actions',
    //   width: 70,
    //   key: 'action',
    //   // fixed: 'right',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div style={{display: 'inline-block', width: '100%'}}>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='View'>
    //             <Button
    //               shape='circle'
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 this.previewInventoryForm(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //         {/* <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Edit'>
    //             <Button
    //               shape='circle'
    //               icon={<EditOutlined />}
    //               onClick={() => {
    //                 this.showEditModal(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Delete'>
    //             <Popconfirm
    //               onConfirm={() => {
    //                 //call delete inventory api
    //                 this.deleteInventory(item)
    //               }}
    //               title='Are you sure you want to delete inventory?'
    //             >
    //               <Button shape='circle' icon={<DeleteOutlined />} />
    //             </Popconfirm>
    //           </Tooltip>
    //         </div> */}
    //       </div>
    //     )
    //   },
    // },
  ]

  rowSelection = {
    // onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
    //   const {inventoryCartData} = this.props
    //   if (selectedRows && selectedRows.length >= 1) {
    //     this.tempBuyRequests = []
    //     selectedRows.map((selectedItem: any, index: number) => {
    //       let inventoryFilterIndex = -1
    //       if (selectedItem) {
    //         if (inventoryCartData && inventoryCartData.length >= 1) {
    //           inventoryFilterIndex = inventoryCartData.findIndex(
    //             (item: any) => item.stock_id === selectedItem.stock_id
    //           )
    //         }
    //         if (inventoryFilterIndex === -1) {
    //           this.tempBuyRequests.push({
    //             id: selectedItem.id,
    //             stock_id: selectedItem.stock_id,
    //             price: selectedItem.final_price,
    //             price_ori: selectedItem.final_price_ori,
    //             stock_data: selectedItem,
    //           })
    //         }
    //       }
    //     })
    //     console.log('selected', selected)
    //     console.log('this.tempBuyRequests', this.tempBuyRequests)
    //     // await this.props.setInventoryCartData(this.tempBuyRequests)
    //   } else {
    //     this.tempBuyRequests = []
    //   }
    // },
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      this.setState({dataTableSelectedRowKeys: selectedRowKeys})
    },
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange([record], selected)
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange(changeRows, selected)
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status !== 'AVAILABLE', // Column configuration not to be checked
      status: record.status, // se the particular column disable and update the table
    }),
  }

  public addSelectedItemsToCartOnTableChange = async (rows: any, selected: any) => {
    const {inventoryCartData, setInventoryCartData} = this.props

    // if (rows && rows.length >= 1) {
    //   this.tempBuyRequests = []
    //   rows.map((selectedItem: any, index: number) => {
    //     let inventoryFilterIndex = -1
    //     if (selectedItem) {
    //       if (inventoryCartData && inventoryCartData.length >= 1) {
    //         inventoryFilterIndex = inventoryCartData.findIndex(
    //           (item: any) => item.stock_id === selectedItem.stock_id
    //         )
    //       }
    //       console.log("selected", selected)
    //       console.log("inventoryFilterIndex", inventoryFilterIndex)
    //       if (selected && inventoryFilterIndex === -1) {
    //         this.tempBuyRequests.push({
    //           id: selectedItem.id,
    //           stock_id: selectedItem.stock_id,
    //           price: selectedItem.final_price,
    //           price_ori: selectedItem.final_price_ori,
    //           stock_data: selectedItem,
    //         })
    //       } else {
    //         if (inventoryFilterIndex !== -1) {
    //           inventoryCartData.splice(inventoryFilterIndex, 1)
    //           // await setInventoryCartData(inventoryCartData)
    //         }
    //       }
    //     }
    //   })
    //   console.log(this.tempBuyRequests)
    //   console.log("inventoryCartData",inventoryCartData)
    //   await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
    //   Toaster({
    //     type: 'success',
    //     title: 'INVENTORY',
    //     description: 'Cart Updated',
    //   })
    // }

    if (rows && rows.length >= 1) {
      if (selected) {
        this.tempBuyRequests = []
        rows.map((selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
            }
            if (inventoryFilterIndex === -1) {
              this.tempBuyRequests.push({
                id: selectedItem.id,
                stock_id: selectedItem.stock_id,
                stock_data: selectedItem,
              })
            }
          }
        })
        await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
      } else {
        rows.map(async (selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
              if (inventoryFilterIndex !== -1) {
                inventoryCartData.splice(inventoryFilterIndex, 1)
                await setInventoryCartData(inventoryCartData)
              }
            }
          }
        })
      }
      // Toaster({
      //   type: 'success',
      //   title: 'INVENTORY',
      //   description: 'Cart Updated',
      // })
    }
  }

  public generateCsv = async () => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          exportAllStockList()
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'STOCK ID',
                'STATUS',
                'IMAGE',
                'VIDEO',
                'SHAPE',
                'WEIGHT',
                'COLOR',
                'CLARITY',
                'CUT',
                'POLISH',
                'SYM',
                'FL',
                'LAB',
                'CERTIFICATE',
                'DISCOUNTS',
                '$/CT',
                'FINAL PRICE',
                'FINAL PRICE ORI',
                'AVAILABILITY',
                'BRAND',
                'CROWN ANGLE',
                'CROWN HEIGHT',
                'CULET CONDITION',
                'DEPTH',
                'TYPE',
                'GIRDLE CONDITION',
                'LW RATIO',
                'MEAS DEPTH',
                'MEASUREMENTS',
                'PAVILION ANGLE',
                'PAVILION DEPTH',
                'RAP PER CARET',
                'RAP PRICE',
                'TABLE',
                'TREATMENT',
              ])

              res.data.docs.map((data: any) => {
                csvArray.push([
                  data && data.stock_id ? returnStockId(data.stock_id) : 'NA',
                  data && data.status ? data.status : 'NA',
                  data && data.diamond_image ? data.diamond_image : 'NA',
                  data && data.diamond_video ? data.diamond_video : 'NA',
                  data && data.shape ? returnStockId(data.shape) : 'NA',
                  data && data.weight ? data.weight : 'NA',
                  data && data.color ? returnStockId(data.color) : 'NA',
                  data && data.clarity ? returnStockId(data.clarity) : 'NA',
                  data && data.cut ? returnStockId(data.cut) : 'NA',
                  data && data.polish ? returnStockId(data.polish) : 'NA',
                  data && data.symmetry ? returnStockId(data.symmetry) : 'NA',
                  data && data.fluorescence_intensity
                    ? returnStockId(data.fluorescence_intensity)
                    : 'NA',
                  data && data.lab_grown_type ? data.lab_grown_type : 'NA',
                  data && data.certificate_number ? data.certificate_number : 'NA',
                  data && data.discounts ? data.discounts : 'NA',
                  data && data.price_per_caret ? data.price_per_caret : 'NA',
                  data && data.final_price ? data.final_price : 'NA',
                  data && data.final_price_ori ? data.final_price_ori : 'NA',
                  data && data.availability ? returnStockId(data.availability) : 'NA',
                  data && data.brand ? returnStockId(data.brand) : 'NA',
                  data && data.crown_angle ? data.crown_angle : 'NA',
                  data && data.crown_height ? data.crown_height : 'NA',
                  data && data.culet_condition ? returnStockId(data.culet_condition) : 'NA',
                  data && data.depth ? data.depth : 'NA',
                  data && data.diamond_type ? returnStockId(data.diamond_type) : 'NA',
                  data && data.girdle_condition ? returnStockId(data.girdle_condition) : 'NA',
                  data && data.lw_ratio ? data.lw_ratio : 'NA',
                  data && data.meas_depth ? data.meas_depth : 'NA',
                  data && data.measurements ? data.measurements : 'NA',
                  data && data.pavilion_angle ? data.pavilion_angle : 'NA',
                  data && data.pavilion_depth ? data.pavilion_depth : 'NA',
                  data && data.rap_per_caret ? data.rap_per_caret : 'NA',
                  data && data.rap_price ? data.rap_price : 'NA',
                  data && data.table ? data.table : 'NA',
                  data && data.treatment ? returnStockId(data.treatment) : 'NA',
                ])
              })

              const rows = csvArray
              let csvName = 'Stock.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err) => {
              console.log('ERROR', err)

              this.setState({
                isLoading: true,
              })

              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
      this.setState({
        isLoading: true,
      })
    }
  }

  public generateCsvOfSelectedItems = async () => {
    try {
      const {inventoryCartData} = this.props

      let stockIds: any = []

      if (inventoryCartData && inventoryCartData.length >= 1) {
        inventoryCartData.map((items: any, index: number) => {
          stockIds.push(items.id)
        })
      }

      this.setState(
        {
          isLoading: true,
        },
        () => {
          exportSelectedStockList(stockIds)
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'STOCK ID',
                'STATUS',
                'IMAGE',
                'VIDEO',
                'SHAPE',
                'WEIGHT',
                'COLOR',
                'CLARITY',
                'CUT',
                'POLISH',
                'SYM',
                'FL',
                'LAB',
                'CERTIFICATE',
                'DISCOUNTS',
                '$/CT',
                'FINAL PRICE',
                'FINAL PRICE ORI',
                'AVAILABILITY',
                'BRAND',
                'CROWN ANGLE',
                'CROWN HEIGHT',
                'CULET CONDITION',
                'DEPTH',
                'TYPE',
                'GIRDLE CONDITION',
                'LW RATIO',
                'MEAS DEPTH',
                'MEASUREMENTS',
                'PAVILION ANGLE',
                'PAVILION DEPTH',
                'RAP PER CARET',
                'RAP PRICE',
                'TABLE',
                'TREATMENT',
              ])

              res.data.docs.map((data: any) => {
                csvArray.push([
                  data && data.stock_id ? returnStockId(data.stock_id) : 'NA',
                  data && data.status ? data.status : 'NA',
                  data && data.diamond_image ? data.diamond_image : 'NA',
                  data && data.diamond_video ? data.diamond_video : 'NA',
                  data && data.shape ? returnStockId(data.shape) : 'NA',
                  data && data.weight ? data.weight : 'NA',
                  data && data.color ? returnStockId(data.color) : 'NA',
                  data && data.clarity ? returnStockId(data.clarity) : 'NA',
                  data && data.cut ? returnStockId(data.cut) : 'NA',
                  data && data.polish ? returnStockId(data.polish) : 'NA',
                  data && data.symmetry ? returnStockId(data.symmetry) : 'NA',
                  data && data.fluorescence_intensity
                    ? returnStockId(data.fluorescence_intensity)
                    : 'NA',
                  data && data.lab_grown_type ? data.lab_grown_type : 'NA',
                  data && data.certificate_number ? data.certificate_number : 'NA',
                  data && data.discounts ? data.discounts : 'NA',
                  data && data.price_per_caret ? data.price_per_caret : 'NA',
                  data && data.final_price ? data.final_price : 'NA',
                  data && data.final_price_ori ? data.final_price_ori : 'NA',
                  data && data.availability ? returnStockId(data.availability) : 'NA',
                  data && data.brand ? returnStockId(data.brand) : 'NA',
                  data && data.crown_angle ? data.crown_angle : 'NA',
                  data && data.crown_height ? data.crown_height : 'NA',
                  data && data.culet_condition ? returnStockId(data.culet_condition) : 'NA',
                  data && data.depth ? data.depth : 'NA',
                  data && data.diamond_type ? returnStockId(data.diamond_type) : 'NA',
                  data && data.girdle_condition ? returnStockId(data.girdle_condition) : 'NA',
                  data && data.lw_ratio ? data.lw_ratio : 'NA',
                  data && data.meas_depth ? data.meas_depth : 'NA',
                  data && data.measurements ? data.measurements : 'NA',
                  data && data.pavilion_angle ? data.pavilion_angle : 'NA',
                  data && data.pavilion_depth ? data.pavilion_depth : 'NA',
                  data && data.rap_per_caret ? data.rap_per_caret : 'NA',
                  data && data.rap_price ? data.rap_price : 'NA',
                  data && data.table ? data.table : 'NA',
                  data && data.treatment ? returnStockId(data.treatment) : 'NA',
                ])
              })

              const rows = csvArray
              let csvName = 'Stock.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err) => {
              console.log('ERROR', err)
              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
    }
  }

  // public addSelectedItemsToCart = async () => {
  //   const {inventoryCartData, setInventoryLoading, setInventoryCartData} = this.props
  //   try {
  //     await setInventoryLoading(true)
  //     if (this.tempBuyRequests && this.tempBuyRequests.length >= 1) {
  //       await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
  //       this.tempBuyRequests = []
  //       Toaster({
  //         type: 'success',
  //         title: 'INVENTORY',
  //         description: 'Items Added to cart',
  //       })
  //     } else {
  //       Toaster({
  //         type: 'error',
  //         title: 'INVENTORY',
  //         description: 'Please select item or item is already added to the cart',
  //       })
  //     }
  //   } catch (e) {
  //     console.log('!!!!Error', e)
  //   } finally {
  //     await setInventoryLoading(false)
  //   }
  // }

  public render() {
    const {inventoryData, inventoryDataTotal, inventoryLoading, loginUser, inventoryCartData} =
      this.props
    const {searchText, inventorySelectedData, dataTableSelectedRowKeys} = this.state

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (loginUser && loginUser.role !== 'vendor' && !columnExists('vendor_details')) {
      this.columns.unshift({
        title: 'VENDOR',
        key: 'vendor_details',
        // width: 150,
        render: (text, item, index) => {
          return (
            <div>
              {item.vendor_details
                ? item.vendor_details.first_name && item.vendor_details.last_name
                  ? `${item.vendor_details.first_name} ${item.vendor_details.last_name}`
                  : item.vendor_details.first_name
                  ? item.vendor_details.first_name
                  : '-'
                : '-'}
            </div>
          )
        },
      })
    }

    return (
      <div>
        <div>
          {!this.state.isLoading ? (
            <div className='card card-custom inventory-list'>
              {loginUser?.role === 'admin' && (
                <div className='card-header justify-content-end'>
                  {/* <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Inventory'
                  input={{
                    value: searchText,
                  }}
                />
              </h3> */}
                  <div className='card-toolbar'>
                    <div>
                      <InputButtonComponent
                        onClick={() => {
                          this.onCreateInventory()
                        }}
                      >
                        Import Stock
                      </InputButtonComponent>
                    </div>
                    <div className='ms-3'>
                      <InputButtonComponent
                        onClick={() => {
                          this.generateCsv()
                        }}
                      >
                        Export Stock
                      </InputButtonComponent>
                    </div>

                    {/* {loginUser?.role === 'client' && (
                  <div>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to cart
                    </InputButtonComponent>
                  </div>
                )} */}
                  </div>
                </div>
              )}
              {loginUser?.role === 'client' && (
                <div className='card-header justify-content-end'>
                  <div className='card-toolbar'>
                    <div>
                      <InputButtonComponent
                        onClick={() => {
                          this.generateCsvOfSelectedItems()
                        }}
                      >
                        Export Stock
                      </InputButtonComponent>
                    </div>

                    {/* {loginUser?.role === 'client' && (
                  <div>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to cart
                    </InputButtonComponent>
                  </div>
                )} */}
                  </div>
                </div>
              )}

              <div className='card-body'>
                <div>
                  <ListTable
                    rowKey={(record: {id: any}) => record.id}
                    onChange={this.handleTableChange}
                    onPaginationChange={(e: any) => {
                      this.setInitialDataTableValue()
                      if (e - 1) {
                        this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                        return
                      }

                      this.counter = 0
                      this.tempCounter = 0
                    }}
                    column={this.columns}
                    paginationConfig={{
                      total: inventoryDataTotal,
                      showSizeChanger: true,
                      pageSize: this.state.pagination.pageSize,
                      current: this.state.pagination.current,
                    }}
                    isLoading={inventoryLoading}
                    dataSource={inventoryData}
                    counter={this.counter}
                    tempCounter={this.tempCounter}
                    isScroll={true}
                    scrollWidth={loginUser.role === 'vendor' ? 1350 : 1500}
                    // rowSelection={
                    //   loginUser?.role !== 'admin'
                    //     ? {
                    //         type: 'checkbox',
                    //         ...this.rowSelection,
                    //         selectedRowKeys: dataTableSelectedRowKeys
                    //           ? dataTableSelectedRowKeys
                    //           : [],
                    //       }
                    //     : undefined
                    // }
                  />
                </div>
              </div>
            </div>
          ) : (
            <Spin style={{margin: 'auto 45%'}} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  searchParamsData: state.inventory.searchParamsData,
  inventoryData: state.inventory.inventoryData,
  inventoryDataTotal: state.inventory.inventoryDataTotal,
  inventoryLoading: state.inventory.inventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListInventoryData: (inventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(inventoryData))
    },
    setListInventoryDataTotal: (inventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(inventoryDataTotal))
    },
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListInventoryData))
