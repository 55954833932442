/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Modal, Upload, Button} from 'antd'
import styled from 'styled-components'
import {InboxOutlined, PictureOutlined} from '@ant-design/icons'
import {message} from 'antd'
import {Toaster} from '../components/ToasterNoti'

const Dragger = Upload.Dragger

const StyledDragger = styled(Dragger)`
  & .ant-upload {
    margin-bottom: 15px;
  }

  & .ant-upload-drag {
    margin-bottom: 15px;
  }
  & .ant-upload-list-item-actions {
    display: none;
  }
`

type Props = {
  onBlur: (value: boolean) => void
  onUpload: (fileName: string, fileObject: Array<any>, fileObjectWithType: Object) => void
  buttonLabel?: string
  name: string
  input: {
    value?: boolean
    id: string
    name: string
    action: string
    headers: {
      authorization: string
    }
    accept: string
    acceptMimeTypes: Array<string>
    mediaType: string
    mediaModuleType?: string
  }
  disabled?: boolean
  error: any
  touched: any
}

const InputSingleFileUpload = forwardRef(
  (
    {
      onBlur,
      onUpload,
      input,
      name,
      buttonLabel = 'Choose Media to Upload',
      disabled = false,
      error,
      touched,
    }: Props,
    ref
  ) => {
    let temp: any[] = []
    let uploadStatus: string = 'uploading'

    const [visible, setVisible] = useState(false)

    /**
     * useImperativeHandle method defined the functions that used by ref in another components
     */
    // useImperativeHandle(ref, () => ({
    //   show() {
    //     setVisible(true)
    //   },
    // }))

    const show = () => {
      setVisible(true)
    }

    /**
     * beforeUpload method check the file based on defined post type and validate every file
     * @param {object} file single file object gets if uploading multiple files
     * @returns boolean
     */
    const beforeUpload = (file: any) => {
    

      const isValid = input.acceptMimeTypes?.includes(file.type)
      if (!isValid) {
        // message.error('Please select valid File Type', 3)
        Toaster({
          type: 'error',
          title: 'Upload',
          description:'Please select valid File Type',
        })
      }
    
      return isValid
    }

    /**
     * onHandleChangeUpload method triggers on every change on file input/drop zone
     * and store updated file list in defined var
     * @param {*} info file and fileList object of the uploaded file
     */
    const onHandleChangeUpload = (info: any) => {
      const {status} = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.code === 'ERROR_IN_UPLOAD') {
          Toaster({
            type: 'error',
            title: 'Upload',
            description: info.file.response.msg,
          })
        } else {
          Toaster({
            type: 'success',
            title: 'Upload',
            description: `${info.file.name} file uploaded successfully.`,
          })
          //store the uploaded file at the server side and store in state on success upload at server side start
          temp = info.fileList
          //store the uploaded file at the server side and store in state on success upload at server side end
        }
      } else if (status === 'removed') {
        if (
          info &&
          info.file &&
          info.file.response &&
          info.file.response.code === 'FILE_UPLOADED'
        ) {
          //find the removed file in the state and remove accordingly start
          let mediaIndex = temp.findIndex((item) => item.uid === info.file.uid)
          if (mediaIndex !== -1) {
            temp.splice(mediaIndex, 1)
          }
          //find the removed file in the state and remove accordingly end
        }
        Toaster({
          type: 'success',
          title: 'Upload',
          description: `${info.file.name} file removed successfully`,
        })
      } else if (status === 'error') {
        Toaster({
          type: 'error',
          title: 'Upload',
          description: `${info.file.name} file upload failed`,
        })
      }
      uploadStatus = status //set the uploading status of the file
    }

    /**
     * verifyDoc finally submit the uploaded media to the parent component
     */
    const verifyDoc = () => {
      if (temp.length <= 0) {
        Toaster({
          type: 'error',
          title: 'Upload',
          description:'Please select a valid File',
        })
        return
      }
      if (uploadStatus === 'uploading') {
        message.error('Please wait till media upload process finish')
        return
      }

      const fileData = {
        // media_name: temp[0].response.file,
        media_name: temp[0].response.name,
        type: input.mediaType,
        media_type: input.mediaModuleType,
      }

      //call the onUpload props pass into the input props
      onUpload(temp[0].response.file, temp, fileData)

      //hide the modal popup
      setVisible(false)
    }

    /**
     * onDropOffMedia on drop media into the drop zone and handle the file upload process
     * @param {any} e event
     */
    const onDropOffMedia = (e: any) => {
      console.log('Dropped files', e.dataTransfer.files)
    }

    const handleCancel = (e: any) => {
      hide()
    }

    const hide = () => {
      setVisible(false)
      onUpload('', [], [])
    }

    return (
      <>
        <div style={{float: 'left', width: '10%'}}>
          <PictureOutlined style={{width: '100%', fontSize: '30px'}} />
        </div>

        <Button style={{width: '90%'}} onClick={() => show()}>
          {buttonLabel}
        </Button>

        {touched && error && error[name] && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>{error[name]}</div>
          </div>
        )}

        {visible && (
          <Modal width='600px' visible={visible} onCancel={handleCancel} footer={null}>
            <div style={{marginTop: '20px'}}>
              <StyledDragger
                className='clearfix'
                beforeUpload={beforeUpload}
                onChange={onHandleChangeUpload}
                onBlur={onBlur}
                onDrop={onDropOffMedia}
                {...input} //input props
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>

                <p className='ant-upload-text'>Click or drag file to this area to upload</p>

                <p className='ant-upload-hint' style={{marginBottom: '10px'}}>
                  Support for a single or bulk upload. Strictly prohibit from uploading company data
                  or other band files
                </p>
              </StyledDragger>

              <Button
                style={{marginTop: '50px'}}
                // disabled={uploadStatus === 'uploading'}
                onClick={verifyDoc}
              >
                Upload
              </Button>
            </div>
          </Modal>
        )}
      </>
    )
  }
)

export {InputSingleFileUpload}
