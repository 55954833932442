/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Table} from 'antd'
import React, {useEffect} from 'react'

type Props = {
  onChange: (pagination: any, filters: any) => void
  onPaginationChange: (e: any) => void
  size?: string
  rowKey: any
  column: Array<{
    title: string
    width?: number
    key: string
    dataIndex?: string
    fixed?: boolean | string
    render: (text: string, record: any, index: number) => void
  }>
  // pagination: Array<{
  //   total?: number
  //   showSizeChanger: boolean
  //   pageSize: number
  //   current: number
  // }>
  paginationConfig: {
    total?: number
    showSizeChanger: boolean
    pageSize: number
    current: number
  }
  isLoading: boolean
  dataSource: any
  counter: number
  tempCounter: number
  isScroll?: boolean
  scrollWidth?: number
  rowSelection?: any
  pagination?: boolean
}

const ListTable: React.FC<Props> = ({
  onChange,
  size = 'small',
  rowKey,
  column,
  paginationConfig,
  isLoading = false,
  dataSource,
  counter,
  tempCounter,
  isScroll,
  scrollWidth,
  onPaginationChange,
  rowSelection,
  pagination = true,
}) => {
  useEffect(() => {}, [counter, tempCounter])

  return (
    <>
      <Table
        loading={isLoading}
        rowKey={rowKey}
        columns={column}
        size={size}
        scroll={isScroll ? {x: scrollWidth} : null}
        dataSource={dataSource}
        pagination={
          pagination
            ? {
                ...paginationConfig,
                pageSizeOptions: ['1', '10', '25', '50', '100'],
                onChange: (e: any) => {
                  onPaginationChange(e)
                },
              }
            : pagination
        }
        rowSelection={rowSelection ? {...rowSelection} : undefined}
        onChange={onChange}
      />
    </>
  )
}

export {ListTable}
