import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {Tag, Alert, Button} from 'antd'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Spin} from 'antd'
import html2pdf from 'html2pdf.js'
import download from 'downloadjs'
import {getOrderStockDetails} from '../redux/OrderCRUD'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'

interface IPreviewOrderProps extends RouteComponentProps<any> {
  handleCancel?: any
  loginUserData?: any
  orderData?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
class MeleeOrderDetailsCsv extends React.Component<IPreviewOrderProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      orderRequest: {},
      shipmentAddressDataState: {},
      billingAddressDataState: {},
      vendorDataState: [],
      shippedApproveState: true,
      paidApproveState: true,
      cancelButtonDisable: true,
      isLoading: false,
      orderStatusState: '',
      failureReasonState: '',
      showErrorFailureReason: false,
      showError: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isFileLinked: false,
      file_url: '',
      orderStatusData: [
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Failed',
          value: 'FAILED',
        },
      ],
      jewelleryDataState: [],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      this.setState({
        isLoading: true,
      })

      let stockDetails: any = await getOrderStockDetails(this.props.orderData.id)

      this.setState({
        orderRequest: stockDetails.data,
        shipmentData:
          stockDetails.data && stockDetails.data.shipment_price
            ? stockDetails.data.shipment_price
            : 0,
        data: stockDetails?.data.buy_request_details?.stock_ids,
        clientDataState: stockDetails.data.user ? stockDetails.data.user : {},
        shipmentAddressDataState: stockDetails.data.shipping_address
          ? stockDetails.data.shipping_address
          : {},
        billingAddressDataState: stockDetails.data.billing_address
          ? stockDetails.data.billing_address
          : {},
        meleeDataState:
          stockDetails.data && stockDetails.data.melee_array ? stockDetails.data.melee_array : [],
      })
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public calculateTotal = () => {
    const {data} = this.state
    if (data && data.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.map((invItem: any) => {
        this.totalPrice += invItem.stock.final_price
        this.totalDiscount += invItem.stock.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }
  vendorsDataTempCounter = 0
  vendorsDataCounter = 0

  public meleeDataColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.vendorsDataTempCounter}</span>
          </div>
        )
      },
    },
    // {
    //   title: 'STOCK ID',
    //   key: 'stock_id',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <a href={`/inventory/preview/${item.stock.id ? item.stock.id : ''}`} target='_blank'>
    //           <strong>{item.stock && item.stock.stock_id ? item.stock.stock_id : '-'}</strong>
    //         </a>
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Shape',
      key: 'shape',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.shape ? item.data.shape : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Clarity',
      key: 'clarity',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.clarity ? item.data.clarity : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'GrowthType',
      key: 'growthType',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.growthType ? item.data.growthType : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Color Type',
      key: 'color_type',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.color_type ? item.data.color_type : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Fancy Color',
      key: 'fancyColor',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.fancyColor ? item.data.fancyColor : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Fancy Overtone',
      key: 'fancyOvertone',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.fancyOvertone ? item.data.fancyOvertone : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Fancy Intensity',
      key: 'fancyIntensity',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.data && item.data.fancyIntensity ? item.data.fancyIntensity : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'White Color',
      key: 'whiteColor',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.whiteColor ? item.data.whiteColor : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'sieve size',
      key: 'sieveSize',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.sieveSize ? item.data.sieveSize : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'mm',
      key: 'mm',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.mm ? item.data.mm : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Pointer',
      key: 'pointer',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.data && item.data.pointer ? item.data.pointer : '-'}</strong>
          </div>
        )
      },
    },
    // {
    //   title: 'Vendor Name',
    //   key: 'vendor',
    //   sorter: (a: any, b: any) => {
    //     const fullNameA = `${(a.vendor && a.vendor.first_name) || ''} ${
    //       (a.vendor && a.vendor.last_name) || ''
    //     }`.trim()
    //     const fullNameB = `${(b.vendor && b.vendor.first_name) || ''} ${
    //       (b.vendor && b.vendor.last_name) || ''
    //     }`.trim()
    //     return fullNameA.localeCompare(fullNameB)
    //   },
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <strong>
    //           {item.vendor && item?.vendor?.first_name && item?.vendor?.last_name
    //             ? `${item.vendor.first_name} ${item.vendor.last_name}`
    //             : item.vendor.first_name
    //             ? item.vendor.first_name
    //             : '-'}
    //         </strong>
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Company',
    //   key: 'vendor',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <strong>
    //           {item.vendor && item?.vendor?.company_name ? item.vendor.company_name : '-'}
    //         </strong>
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Status',
    //   key: 'order_status',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item && item.status ? (
    //           <Tag
    //             color={
    //               config.statusTagColor[item.status]
    //                 ? config.statusTagColor[item.status]
    //                 : 'default'
    //             }
    //           >
    //             {item.status}
    //           </Tag>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Order Date',
    //   key: 'createdAt',
    //   sorter: (a: any, b: any) => {
    //     const dateA: any = new Date(a.createdAt)
    //     const dateB: any = new Date(b.createdAt)
    //     return dateA - dateB
    //   },
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
    //   },
    // },
    {
      title: 'Price Per Carat',
      key: 'price_per_carat',
      render: (text: any, item: any, index: number) => {
        return <div>$ {item.price_per_carat ? item.price_per_carat : '0'}</div>
      },
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (text: any, item: any, index: number) => {
        console.log({item})
        return (
          <div>
            <strong>{item.quantity ? item.quantity : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      render: (text: any, item: any, index: number) => {
        return <div>$ {item.total_amount ? parseFloat(item.total_amount).toFixed(2) : '0'}</div>
      },
    },
    // {
    //   title: 'Dollar Rate',
    //   key: 'dollar_rate',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.dollar_rate ? item.dollar_rate : '0'}</div>
    //   },
    // },
    // {
    //   // title: 'Shape',
    //   title: 'SHAPE',
    //   key: 'shape',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Color',
    //   title: 'COLOR',
    //   key: 'color',
    //   // ellipsis: true,
    //   // width: 40,
    //   width: 65,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Clarity',
    //   title: 'CLARITY',
    //   key: 'clarity',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
    //     )
    //   },
    // },
    // {
    //   // title: 'Cut',
    //   title: 'CUT',
    //   key: 'cut',
    //   // width: 40,
    //   width: 45,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Polish',
    //   title: 'POLISH',
    //   // ellipsis: true,
    //   key: 'polish',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Symmetry',
    //   title: 'SYM',
    //   // ellipsis: true,
    //   key: 'symmetry',
    //   width: 50,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
    //     )
    //   },
    // },
    // {
    //   // title: 'Discounts',
    //   title: 'DISCOUNTS',
    //   // ellipsis: true,
    //   key: 'discounts',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.discounts ? item.stock.discounts : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Price Per Caret',
    //   title: '$/CT',
    //   // ellipsis: true,
    //   key: 'price_per_caret',
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
    //     )
    //   },
    // },
    // {
    //   // title: 'Final Price',
    //   title: 'FINAL PRICE',
    //   // ellipsis: true,
    //   key: 'final_price',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.stock && item.stock.final_price
    //           ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
    //           : '-'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div style={{display: 'inline-block', width: '100%'}}>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Edit'>
    //             <Button
    //               shape='circle'
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 this.showPreviewModal(item)
    //                 // this.navigateToPreviewOrderPage(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
  ]

  public calculateAvgDiscount = () => {
    const {data} = this.state
    return returnPriceInThousandFormat(this.totalDiscount / data.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    this.shipMentPrice = shipmentData ? shipmentData : 0
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public navigateToPage = (path: string) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}${path}`)
  }

  exportToPDF = async () => {
    try {
      const element = document.querySelector('.export-pdf-order-details') // Replace with your JSX element ID
      const pdfOptions = {
        margin: 10,
        filename: 'order-details.pdf', // Customize the file name
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: 'mm', orientation: 'landscape'},
      }
      console.log('clicked', element, pdfOptions)
      const pdf = await html2pdf().from(element).set(pdfOptions).output('blob')

      download(pdf, pdfOptions.filename, 'application/pdf')
    } catch (e) {
      console.log(e)
    } finally {
      this.props.handleCancel()
    }
  }

  public render() {
    const {loginUserData} = this.props
    const {
      data,
      orderRequest,
      billingAddressDataState,
      shipmentAddressDataState,
      isLoading,
      stockDetails,
    } = this.state
    console.log({billingAddressDataState})
    return (
      <>
        <div className='export-pdf-order-details'>
          <div className='card card-custom'>
            <Spin spinning={isLoading} tip='Loading...'>
              {/* <div className='alert alert-primary text-center font-weight-bold' role='alert'>
            {'Please check your mail for further process'}
          </div> */}
              <div className='card-body'>
                <div>
                  <div className='mb-6' style={{position: 'relative'}}>
                    <h3
                      className='font-weight-bold'
                      style={{marginBottom: '0', lineHeight: '150%'}}
                    >
                      {`${orderRequest.order_code && returnStockId(orderRequest.order_code)}`}
                    </h3>
                    <span style={{fontWeight: 500}}>
                      {orderRequest &&
                      orderRequest?.user_details &&
                      orderRequest?.user_details?.business_entity_name
                        ? orderRequest.user_details.business_entity_name
                        : '-'}
                      {' | '}
                      {orderRequest &&
                      orderRequest?.user_details &&
                      orderRequest?.user_details?.first_name &&
                      orderRequest?.user_details?.last_name
                        ? `${orderRequest.user_details.first_name} ${orderRequest.user_details.last_name}`
                        : orderRequest?.user_details?.first_name
                        ? orderRequest?.user_details?.first_name
                        : '-'}
                      {' | '}
                      {orderRequest &&
                      orderRequest?.user_details &&
                      orderRequest?.user_details?.email
                        ? orderRequest.user_details.email
                        : '-'}
                    </span>
                    <div
                      className='right-side-details'
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                      }}
                    >
                      <div>
                        {' '}
                        <h3
                          className='font-weight-bold text-right'
                          style={{marginBottom: '0', lineHeight: '150%'}}
                        >
                          {orderRequest?.order_status && (
                            <Tag
                              className='p-2'
                              color={
                                config.statusTagColor[orderRequest.order_status]
                                  ? config.statusTagColor[orderRequest.order_status]
                                  : 'default'
                              }
                            >
                              {orderRequest.order_status}
                            </Tag>
                          )}
                        </h3>
                      </div>
                      <span
                        className='font-weight-bold'
                        style={{marginTop: '10px', paddingRight: '8px', fontWeight: 500}}
                      >
                        Date :{' '}
                        {orderRequest.createdAt
                          ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                          : '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                      <h3 className='mb-2'>{'Billing Address'}</h3>
                      <span>
                        {billingAddressDataState && (
                          <span className='text-dark font-weight-normal' style={{fontSize: '14px'}}>
                            {billingAddressDataState.address_line_one
                              ? ` ${billingAddressDataState.address_line_one},`
                              : ''}
                            {billingAddressDataState.address_line_two
                              ? ` ${billingAddressDataState.address_line_two},`
                              : ''}
                            <br />
                            {billingAddressDataState.city
                              ? ` ${billingAddressDataState.city},`
                              : ''}
                            {billingAddressDataState.state
                              ? ` ${billingAddressDataState.state},`
                              : ''}
                            <br />
                            {billingAddressDataState.country
                              ? ` ${billingAddressDataState.country} -`
                              : ''}
                            {billingAddressDataState.zip_code
                              ? ` ${billingAddressDataState.zip_code}`
                              : ''}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                      <h3 className='mb-2'>{'Shipping Address'}</h3>
                      <span>
                        {shipmentAddressDataState && (
                          <div className='text-dark font-weight-normal' style={{fontSize: '14px'}}>
                            {shipmentAddressDataState.address_line_one
                              ? ` ${shipmentAddressDataState.address_line_one},`
                              : ''}
                            {shipmentAddressDataState.address_line_two
                              ? ` ${shipmentAddressDataState.address_line_two},`
                              : ''}
                            {/* {shipmentAddressDataState.street
                              ? ` ${shipmentAddressDataState.street},`
                              : ''} */}
                            <br />
                            {shipmentAddressDataState.city
                              ? ` ${shipmentAddressDataState.city},`
                              : ''}
                            {shipmentAddressDataState.state
                              ? ` ${shipmentAddressDataState.state},`
                              : ''}
                            <br />
                            {shipmentAddressDataState.country
                              ? ` ${shipmentAddressDataState.country} -`
                              : ''}
                            {shipmentAddressDataState.zip_code
                              ? ` ${shipmentAddressDataState.zip_code}`
                              : ''}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  {orderRequest && orderRequest.order_status !== 'PENDING' && (
                    <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                      <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                        <h3
                          className='font-weight-bold mb-2'
                          style={{
                            marginTop: '10px',
                            paddingRight: '8px',
                            fontWeight: 500,
                            fontSize: '14px',
                          }}
                        >
                          {'AWB Number :  '}{' '}
                          <span
                            className='font-weight-bold'
                            style={{fontSize: '13px', color: 'rgba(0, 0, 0, 0.65)'}}
                          >
                            {orderRequest && orderRequest.awb_number
                              ? orderRequest.awb_number
                              : '-'}
                          </span>
                        </h3>
                      </div>
                      <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                        <h3
                          className='font-weight-bold mb-2'
                          style={{
                            marginTop: '10px',
                            paddingRight: '8px',
                            fontWeight: 500,
                            fontSize: '14px',
                          }}
                        >
                          {'Courier Company :  '}{' '}
                          <span
                            className='font-weight-bold'
                            style={{fontSize: '13px', color: 'rgba(0, 0, 0, 0.65)'}}
                          >
                            {orderRequest && orderRequest.courier_company
                              ? orderRequest.courier_company
                              : '-'}
                          </span>
                        </h3>
                      </div>
                    </div>
                  )}

                  {/* <div className='row border-bottom pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                    <h3 className='mb-10'>{'Stock Details'}</h3>
                    <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                      {'Stock Id'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                      {'Vendor Name'}
                    </div>
                    <div className='col-lg-5 text-dark font-weight-bold text-muted'>
                      {'Details'}
                    </div>
                    <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>
                        <label>{'Discount'}</label>
                      </div>
                    </div>
                    <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>
                        <label>{'$/Ct'}</label>
                      </div>
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>
                        <label>{'Price'}</label>
                      </div>
                    </div>
                  </div> */}
                  <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                    {data &&
                      !!data.length &&
                      data.map(
                        (invItem: any, index: number) =>
                          invItem && (
                            <div className='row mt-10' key={index}>
                              <div className='col-lg-1 text-dark font-weight-bold'>
                                <label>
                                  <a
                                    href={`/inventory/preview/${
                                      invItem.stock && invItem.stock.stock_id
                                        ? invItem.stock.stock_id
                                        : ''
                                    }`}
                                    target='_blank'
                                  >
                                    {invItem && invItem.stock && invItem.stock.stock_id
                                      ? returnStockId(invItem.stock.stock_id)
                                      : '-'}
                                  </a>
                                </label>
                              </div>
                              <div className='col-lg-2 text-dark font-weight-bold'>
                                <div className='text-left'>
                                  <label>
                                    {invItem && invItem.vendor
                                      ? invItem.vendor.first_name && invItem.vendor.last_name
                                        ? `${invItem.vendor.first_name} ${invItem.vendor.last_name}`
                                        : invItem.vendor.first_name
                                        ? invItem.vendor.first_name
                                        : '-'
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-5 text-dark font-weight-bold'>
                                <label>
                                  {invItem && invItem.stock
                                    ? returnStockString(invItem.stock)
                                    : '-'}
                                </label>
                              </div>
                              <div className='col-lg-1 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>
                                    {invItem && invItem.stock && invItem.stock.discounts
                                      ? invItem.stock.discounts
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-1 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>
                                    {invItem && invItem.stock && invItem.stock.price_per_caret
                                      ? invItem.stock.price_per_caret
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-2 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>
                                    {invItem && invItem.stock && invItem.stock.final_price
                                      ? returnPriceInThousandFormatWithCurrencySign(
                                          invItem.stock.final_price
                                        )
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                  <div className='row border-top mt-10'>
                    <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                      <label>{'Sub Total:'}</label>
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>
                        <label>{this.calculateTotal()} </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Discount:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{`${this.calculateAvgDiscount()}(%)`} </label>
                  </div>
                </div>
              </div> */}
                  <div className='row'>
                    <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                      <label>{'Shipment Price:'}</label>
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>
                        <label>{this.calculateShipMent()} </label>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-10 text-dark font-weight-bold text-end'>
                      {'Tax Price:'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>
                        {orderRequest && orderRequest.tax_price
                          ? returnPriceInThousandFormatWithCurrencySign(orderRequest.tax_price)
                          : '0'}
                      </div>
                    </div>
                  </div>
                  <div className='row border-bottom'>
                    <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                      <label>{'Total:'}</label>
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>
                        {/* <label>{this.calculateGrandTotal()} </label> */}
                        <label>
                          {orderRequest && orderRequest.grand_total
                            ? returnPriceInThousandFormatWithCurrencySign(orderRequest.grand_total)
                            : '0'}{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className='pt-4' style={{marginBottom: '30px'}}>
            <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
              <div style={{fontSize: '20px', fontWeight: 500}}>Melee Stocks Details : </div>
            </div>
          </div>
          {this.state.vendorDataState ? (
            <>
              <div style={{overflowX: 'auto'}}>
                <div className='card-toolbar d-flex justify-content-end mb-4'>
                  <div className='ms-3'>
                    {/* <InputButtonComponent
                          onClick={() => {
                            this.generateCsv()
                          }}
                        >
                          Export Vendor Stock Details
                        </InputButtonComponent> */}
                  </div>
                </div>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={() => {}}
                  onPaginationChange={(e: any) => {}}
                  column={this.meleeDataColumns}
                  paginationConfig={{
                    total: 1000, //this.state.meleeDataState.length,
                    showSizeChanger: false,
                    pageSize: 10000,
                    current: 1,
                  }}
                  isLoading={false}
                  dataSource={this.state.meleeDataState}
                  counter={this.vendorsDataCounter}
                  tempCounter={this.vendorsDataTempCounter}
                  isScroll={true}
                  scrollWidth={1000}
                  pagination={false}

                />
              </div>
            </>
          ) : (
            <div>No Stocks</div>
          )}
        </div>
        <div
          className='card-footer d-flex justify-content-end'
          style={{marginBottom: '40px', paddingRight: '0px'}}
        >
          <button
            type='button'
            className='btn btn-sm'
            onClick={() => this.exportToPDF()}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
            }}
          >
            {'DOWNLOAD'}
          </button>
        </div>
      </>
    )
  }
}

export default withRouter(MeleeOrderDetailsCsv)
