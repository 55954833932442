/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getVendorOrderData, getVendorOrdergroupData} from '../redux/OrderCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as order from '../redux/OrderRedux'
import ListOrderData from './ListOrderData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log({user1: user})
  const callOrderData = async (status: string) => {
    try {
      setIsLoading(true)
      await dispatch(order.actions.setVendorOrderData([]))
      await dispatch(order.actions.setVendorOrderDataTotal(0))
      let orderData
      // if (user.role === 'admin') {
      //   orderData = await getAdminOrderData(0, 10)
      // } else {
      orderData = await getVendorOrderData(0, 10, status)
      // orderData =
      //   user.role === 'vendor'
      //     ? await getVendorOrdergroupData(0, 10, status)
      //     : await getVendorOrderData(0, 10, status)
      // }
      if (orderData.data) {
        await dispatch(order.actions.setVendorOrderLoading(true))
        // once order data set then loading will automatically off using saga middleware
        await dispatch(order.actions.setVendorOrderData(orderData.data))
        await dispatch(order.actions.setVendorOrderDataTotal(orderData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    if (key === '5') {
      callOrderData('CANCELED')
    } else if (key === '4') {
      callOrderData('PAID')
    } else if (key === '3') {
      callOrderData('DELIVERED')
      // callOrderData('PAID')
      // callOrderData('SHIPPED')
    } else if (key === '2') {
      callOrderData('SHIPPED')
    } else {
      callOrderData('PENDING')
    }
  }

  useEffect(() => {
    callOrderData('PENDING')
  }, [])

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div className='card-container'>
        <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='Pending' key='1'>
            <ListOrderData tabkey='PENDING' />
          </TabPane>
          <TabPane tab='Shipped' key='2'>
            <ListOrderData tabkey='SHIPPED' />
          </TabPane>
          <TabPane tab={user.role === 'super_admin' ? 'Received' : 'Delivered'} key='3'>
            <ListOrderData tabkey='DELIVERED' />
          </TabPane>
          <TabPane tab='Paid' key='4'>
            <ListOrderData tabkey='PAID' />
          </TabPane>
          <TabPane tab='Cancelled' key='5'>
            <ListOrderData tabkey='CANCELED' />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/vendor-order/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/vendor-order/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.VENDORORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
